import * as React from 'react'
import { useEffect, useState } from 'react'
import { Redirect, useHistory } from 'react-router'
import { OAuth } from 'v2a-implicit-oauth'
import { useApplicationStore } from '../../hooks/useApplicationStore'
import { LoadingIndicator } from '../shared/LoadingIndicator'
import { useEnv } from '../../../shared/hooks/useEnv'

export const LoginCallbackPage = () => {
  const { api } = useEnv()
  const store = useApplicationStore()
  const history = useHistory()

  const [loading, setLoading] = useState(true)
  const [loggedIn, setLoggedIn] = useState(false)

  useEffect(() => {
    ;(async () => {
      if (OAuth.redirectOccurred(window)) {
        const { access_token } = OAuth.parseHash(window)
        window.localStorage.setItem('accessToken', access_token)
        api.setAccessToken(access_token)
        store.setAccessToken(access_token)
        try {
          await store.fetchOwnUser()
          setLoading(false)
        } catch (e) {
          history!.push('/login/do')
          setLoading(false)
        }

        setLoggedIn(true)
      }
    })()
  }, [])

  if (loading) return <LoadingIndicator white />
  if (loggedIn) return <Redirect to="/" />
  return <div />
}
