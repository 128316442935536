import 'core-js/features/set-immediate'
import 'core-js/features/promise'
import 'core-js/features/object/assign'
import 'core-js/features/array/find'
import 'core-js/features'
import 'whatwg-fetch'

// ChildNode.remove() polyfill, hopefully landing in core-js soon.
;(function (arr) {
  arr.forEach(function (item) {
    if (item.hasOwnProperty('remove')) {
      return
    }
    Object.defineProperty(item, 'remove', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove() {
        if (this.parentNode !== null) this.parentNode.removeChild(this)
      },
    })
  })
})([Element.prototype, CharacterData.prototype, DocumentType.prototype])

import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { APIService } from '../shared/services/APIService'
import { Application } from './components/Application'

window.onload = () => {
  const container = document.querySelector<HTMLDivElement>('#container')!
  const { dataset } = container

  ReactDOM.render(
    <BrowserRouter>
      <Application
        apiService={new APIService()}
        oauthApiEndpoint={dataset.oauthApiEndpoint!}
        oauthClientId={dataset.oauthClientId!}
      />
    </BrowserRouter>,
    container
  )
}
