import * as _ from 'lodash'
import * as qs from 'querystring'
import { CategoryInstance } from '../models/Category'
import { PostingInstance } from '../models/Posting'
import { TagInstance } from '../models/Tag'

export interface IAPITagData {
  tagId: string
  name: string
}

export interface IAPICategoryData {
  categoryId: string
  name: string
}

export interface IAPIPostingData {
  postingId: string
  author: IAPIUserData
  tags: IAPITagData[]
  category: IAPICategoryData
  files: IAPIFileData[]

  title: string
  description: string
  rationale: string
  results: string
  costs: string

  comments: {
    count: number
    _link: string
  }

  votes: {
    myVote: number
    voteCount: number
    totalScore: number
  }

  createdAt: string
}

export interface IAPIUserData {
  userId: string
  email: string
  nickname: string
  role: string
  forename: string
  surname: string
  country: string
}

export interface IAPICommentData {
  commentId: string
  author: IAPIUserData
  content: string
  createdAt: string
}

export interface IAPIFileData {
  fileId: string
  name: string
  type: string
  url: string
  size: number
}

export interface IAPIListResponse {
  _embedded: {}
  _page: number
  _per_page: number
  _total: number
}

export interface IAPIPostingsListResponse extends IAPIListResponse {
  _embedded: {
    postings: IAPIPostingData[]
  }
}

export interface IAPICommentsListResponse extends IAPIListResponse {
  _embedded: {
    comments: IAPIPostingData[]
  }
}

export interface IAPIUsersListResponse {
  users: IAPIUserData[]
}

export interface IAPIUDBUserData {
  user_id: string
  email: string
  nickname: string
  forename: string
  surname: string
  apps: {
    app_id: string
    name: string
    app_uri: string
  }[]
  street: string
  zip_code: string
  city: string
  country: string
}

export type APITagsResponse = Promise<IAPITagData[]>
export const APITagsResponse = Promise

export type APITagResponse = Promise<IAPITagData>
export const APITagResponse = Promise

export type APICategoriesResponse = Promise<IAPICategoryData[]>
export const APICategoriesResponse = Promise

export type APICategoryResponse = Promise<IAPICategoryData>
export const APICategoryResponse = Promise

export type APIPostingsResponse = Promise<IAPIPostingsListResponse>
export const APIPostingsResponse = Promise

export type APIPostingResponse = Promise<IAPIPostingData>
export const APIPostingResponse = Promise

export type APICommentsResponse = Promise<IAPICommentsListResponse>
export const APICommentsResponse = Promise

export type APICommentResponse = Promise<IAPICommentData>
export const APICommentResponse = Promise

export type APIFilesResponse = Promise<IAPIFileData[]>
export const APIFilesResponse = Promise

export type APIUserResponse = Promise<IAPIUDBUserData>
export const APIUserResponse = Promise

export type APIUsersResponse = Promise<IAPIUsersListResponse>
export const APIUsersResponse = Promise

export interface IAPIPostingsRequestOptions {
  category?: string
  tags?: string[]
  limit?: number
  page?: number
  sortBy?: string
  order?: 'ASC' | 'DESC'
  active?: boolean
  title?: string
  categoryActive?: boolean
}

export interface IAPITagsRequestOptions {
  category?: string
  hasContent?: boolean
}

export interface IAPIServiceInterface {
  clientId: string
  sharingApplicationBaseUrl: string
  authEndpoint: string
  authApiEndpoint: string
  sentryDSN: string
  environment: 'development' | 'testing' | 'staging' | 'production'

  fetchConfig: () => Promise<void>
  fetchUser: () => APIUserResponse
  fetchUsers: () => APIUsersResponse

  setAccessToken: (accessToken: string) => void

  fetchTag: (tagId: string) => APITagResponse
  fetchTags: (options?: IAPITagsRequestOptions) => APITagsResponse
  sendTag: (tag: TagInstance) => APITagResponse
  deleteTag: (tagId: string) => Promise<void>

  fetchCategory: (categoryId: string) => APICategoryResponse
  fetchCategories: () => APICategoriesResponse
  sendCategory: (category: CategoryInstance) => APICategoryResponse
  deleteCategory: (categoryId: string) => Promise<void>

  fetchPosting: (postingId: string) => APIPostingResponse
  fetchPostings: (options?: IAPIPostingsRequestOptions) => APIPostingsResponse
  sendPosting: (posting: PostingInstance) => APIPostingResponse
  deletePosting: (postingId: string) => Promise<void>
  softDeletePosting: (postingId: string) => Promise<void>
  votePosting: (postingId: string) => Promise<void>

  fetchComments: (postingId: string) => APICommentsResponse
  sendComment: (postingId: string, content: string) => APICommentResponse
  deleteComment: (postingId: string, commentId: string) => Promise<void>

  searchPostings: (value: string, options?: IAPIPostingsRequestOptions) => APIPostingsResponse
  fetchTopPostings: (options?: IAPIPostingsRequestOptions) => APIPostingsResponse
  fetchNewPostings: (options?: IAPIPostingsRequestOptions) => APIPostingsResponse
  fetchSimilarPostings: (posting: PostingInstance) => APIPostingsResponse

  loadImage: (url: string) => Promise<string>
  sendFiles: (file: FileList) => APIFilesResponse
  deleteFile: (fileId: string) => Promise<void>

  stringifyErrors: (errors: any) => string
}
