import * as React from 'react'
import { styled } from '../../../shared/lib/styled-components'
import { HeartIcon } from '../icons/HeartIcon'
import { PostingInstance } from '../../../shared/models'

interface IProps {
  posting: PostingInstance
}

export const LikesAndComments = ({ posting }: IProps) => {
  return (
    <Container>
      <Item>
        <HeartIconWithSpacing />
        {posting.votes.voteCount} Likes
      </Item>
      <Item>{posting.comments?.count} Comments</Item>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  font-family: ${props => props.theme.primaryFontFamily};
  font-weight: bold;
  color: ${props => props.theme.primaryColor};
  text-transform: uppercase;
  font-size: 12px;
  border-bottom: 1px solid ${props => props.theme.borderColor};
  padding-bottom: 5px;
  width: 100%;
`

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  &:not(:last-child) {
    margin-right: 10px;
  }
`

const HeartIconWithSpacing = styled(HeartIcon)`
  margin-right: 5px;
`
