import * as React from 'react'
import { useEffect, useMemo } from 'react'
import { styled } from '../../../shared/lib/styled-components'
import { useForm } from 'react-hook-form'
import { PostingHeadline } from '../shared/Elements'
import { Posting } from '../../../shared/models'
import { useApplicationStore } from '../../hooks/useApplicationStore'
import { LoadingIndicator } from '../shared/LoadingIndicator'
import { useHistory } from 'react-router'
import { PostingForm } from '../postings/PostingForm'
import { useEnv } from '../../../shared/hooks/useEnv'
import { useSetViewStore } from '../../hooks/useSetViewStore'
import { PostingFormStore } from '../../../shared/stores/PostingFormStore'
import { observer } from 'mobx-react-lite'

interface IProps {}

export const NewPostingPage = observer(({}: IProps) => {
  const env = useEnv()
  const applicationStore = useApplicationStore()
  const store = useMemo(
    () =>
      PostingFormStore.create(
        {
          loadingPosting: false,
        },
        env
      ),
    []
  )
  useSetViewStore(store)

  const form = useForm()
  const history = useHistory()

  const { selectedTags, uploadedFiles, loadingCategories, loadingTags, saving } = store
  const loading = loadingCategories || loadingTags

  useEffect(() => {
    void store.fetchCategories()
    void store.fetchTags()
  }, [])

  const onSubmit = async data => {
    data.tags = selectedTags.map(t => t.tagId)
    data.files = uploadedFiles.map(f => f.fileId)
    data.author = applicationStore.user!.userId

    const newPosting = Posting.create(data)
    store.editPosting(newPosting)
    newPosting.setProp('active', true)

    const posting = await store.savePosting()
    history.push(`/postings/${posting.postingId}`)
  }

  return (
    <Container>
      <PostingHeadline primaryColored withMarginBottom>
        Create a new case
      </PostingHeadline>

      {loading || saving ? (
        <LoadingIndicator white />
      ) : (
        <PostingForm
          {...{ form, onSubmit, selectedTags, uploadedFiles }}
          onSelectedTagsChange={store.setSelectedTags}
          onUploadedFilesChange={files => store.setUploadedFiles(files)}
        />
      )}
    </Container>
  )
})

const Container = styled.div`
  position: relative;
  width: 100%;
  padding: 0 6rem;
  margin: 3rem auto;
  display: flex;
  flex-direction: column;
`
