import { Instance, types } from 'mobx-state-tree'
import Utils from '../lib/Utils'
import { Category } from './Category'
import { File, FileInstance } from './File'
import { Tag, TagInstance } from './Tag'
import { BackendDate } from './types/BackendDate'
import { User } from './User'
import { Votes } from './Votes'

export const Posting = types
  .model('Posting', {
    postingId: types.optional(types.identifier, 'new'),
    originalPostingId: types.maybeNull(types.string),
    title: types.optional(types.string, ''),
    description: types.optional(types.string, ''),
    rationale: types.maybeNull(types.string),
    results: types.maybeNull(types.string),
    costs: types.maybeNull(types.string),
    author: types.maybeNull(types.reference(User)),
    authorHeadline: types.maybeNull(types.string),
    category: types.maybeNull(types.reference(Category)),
    tags: types.optional(types.array(types.reference(Tag)), []),
    files: types.optional(types.array(types.reference(File)), []),
    topPosting: types.optional(types.boolean, false),
    active: types.optional(types.boolean, false),
    comments: types.maybeNull(
      types.model('Comments', {
        count: types.number,
        _link: types.model('Link', {
          href: types.string,
        }),
      })
    ),
    votes: types.optional(Votes, {
      myVote: 0,
      totalScore: 0,
      voteCount: 0,
    }),
    createdAt: types.maybeNull(BackendDate),
    updatedAt: types.maybeNull(BackendDate),
  })
  .actions(self => ({
    toggleTag: (tag: TagInstance) => {
      const tagPosition = self.tags.indexOf(tag)
      if (tagPosition !== -1) {
        self.tags.splice(tagPosition, 1)
      } else {
        self.tags.push(tag)
      }
    },
    setProp: (prop: string, value: any) => {
      self[prop] = value
    },
    addFiles: (fileIds: string[]) => {
      self.files.push(...fileIds)
    },
    moveFile: (oldIndex: number, newIndex: number) => {
      const element = self.files[oldIndex]
      self.files.splice(oldIndex, 1)
      self.files.splice(newIndex, 0, element)
    },
    removeFile: (file: FileInstance) => {
      self.files.splice(self.files.indexOf(file), 1)
    },
    removeFileAtIndex: (index: number) => {
      self.files.splice(index, 1)
    },
    setFile: (index: number, file: FileInstance) => {
      self.files[index] = file
    },
  }))
  .views(self => ({
    get formattedDate() {
      return Utils.formatDate(self.updatedAt!)
    },
    get path() {
      return '/postings/' + self.postingId
    },
    get metaInformation() {
      let authorInformation = self.authorHeadline || `${self.author!.nickname} (${self.author!.country})`
      return `${authorInformation} - ${Utils.formatDate(self.updatedAt!)}`
    },
    get filesCount() {
      return this.fileInstances.filter(f => f).length
    },
    get firstFile() {
      return this.fileInstances[0]
    },
    get fileIds() {
      return this.fileInstances.map(f => f.fileId)
    },
    get fileInstances() {
      return Array.from(self.files.values())
    },
    get tagInstances() {
      return Array.from(self.tags.values())
    },
    getTruncatedDescription: (limit: number) => {
      const str = self.description
      if (str.length <= limit) return str
      const subString = str.substr(0, limit - 1)
      return subString.substr(0, subString.lastIndexOf(' ')) + '...'
    },
  }))

export type PostingInstance = Instance<typeof Posting>
