import { types } from 'mobx-state-tree'

export const BackendDate = types.custom<string, Date>({
  name: 'BackendDate',
  fromSnapshot(value: string): Date {
    return new Date(value)
  },
  toSnapshot(value: Date): string {
    return value.toISOString()
  },
  getValidationMessage(snapshot: any): string {
    if (typeof snapshot !== 'string') {
      return 'Is not a valid Date'
    }
    return ''
  },
  isTargetType(value: any): boolean {
    return value instanceof Date
  },
})
