import * as React from 'react'
import { styled } from '../../../shared/lib/styled-components'
import { FileInstance } from '../../../shared/models'
import { SecuredBackgroundImage } from '../../../shared/components/SecuredBackgroundImage'

interface IProps {
  files: FileInstance[]
  onImageChange?: (file: FileInstance) => any
}

export const Gallery = ({ files, onImageChange }: IProps) => {
  const handleImageClick = (file: FileInstance) => {
    onImageChange?.(file)
  }

  return (
    <Container>
      <NavigationArrow left />
      <Slideshow>
        {files.map(file => (
          <PreviewImageContainer key={file.fileId}>
            <PreviewImage src={file.getFormatUrl('thumb')} onClick={handleImageClick.bind(null, file)} />
          </PreviewImageContainer>
        ))}
      </Slideshow>
      <NavigationArrow right />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 215px;
`

const NavigationArrow = styled.div<{ left?: boolean; right?: boolean }>`
  background: url('/images/icons/gallery-arrow.svg') no-repeat center center;
  flex: 0 0 40px;
  cursor: pointer;
  ${props => props.right && `transform: scaleX(-1)`};
`

const Slideshow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: stretch;
  justify-content: flex-start;
  overflow-x: auto;
`

const PreviewImageContainer = styled.div`
  flex: 0 0 33.3%;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  padding: 0 8px;
  cursor: pointer;
`

const PreviewImage = styled(SecuredBackgroundImage)`
  background: no-repeat center center;
  background-size: cover;
  width: 100%;
  height: 100%;
`
