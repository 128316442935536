import * as qs from 'querystring'
import * as React from 'react'
import { useEffect, useMemo } from 'react'
import { useEnv } from '../../../shared/hooks/useEnv'

export const DoLoginPage = () => {
  const { api } = useEnv()
  const state = useMemo(() => Math.random().toString(36).substring(2), [])
  useEffect(() => {
    const query = {
      client_id: api.clientId,
      redirect_uri: window.location.origin + '/login/callback',
      response_type: 'token',
      state,
    }
    window.location.href = `${api.authEndpoint}?${qs.stringify(query)}`
  }, [])

  return <div />
}
