import { values } from 'mobx'
import { flow, getEnv, Instance, types } from 'mobx-state-tree'
import { Tag, TagInstance } from '../models'
import { IAPIServiceInterface, IAPITagsRequestOptions } from '../services/IAPIServiceInterface'

interface IEnv {
  api: IAPIServiceInterface
}

export const TagStore = types
  .model('TagStore', {
    tags: types.map(Tag),
  })
  .actions(self => {
    const env = getEnv<IEnv>(self)
    const actions = {
      // -------------------------------------------------------------------------- MANAGEMENT

      putTag: (data): TagInstance => {
        const tagInstance = Tag.create(data)
        if (!self.tags.has(data.tagId)) {
          self.tags.put(tagInstance)
        } else {
          self.tags.set(tagInstance.tagId, tagInstance)
        }
        return tagInstance
      },

      putTags: (data, overwrite = false): TagInstance[] => {
        const tags: TagInstance[] = []
        data.forEach(tagData => {
          const tagInstance = actions.putTag(tagData)
          tags.push(tagInstance)
        })
        return tags
      },

      // -------------------------------------------------------------------------- API INTERACTION

      fetchTags: flow(function* (options: IAPITagsRequestOptions) {
        try {
          const response = yield env.api!.fetchTags(options)
          const tags = actions.putTags(response, true)
          return tags
        } catch (e) {
          console.error(e)
        }
      }),

      fetchTag: flow(function* (tagId: string, forEditing = false) {
        try {
          const data = yield env.api.fetchTag(tagId) as any
          if (forEditing) {
            data.originalTagId = data.tagId
            data.tagId = 'editing'
            return Tag.create(data)
          } else {
            const tag = actions.putTag(data)
            return tag
          }
        } catch (e) {
          console.error(e)
        }
      }),

      deleteTag: flow(function* (tag: TagInstance) {
        yield env.api.deleteTag(tag.tagId)
      }),
    }
    return actions
  })
  .views(self => ({
    get tagsAsOptions(): [string, string][] {
      return values(self.tags).map(t => [t.tagId, t.name])
    },

    get instances(): TagInstance[] {
      return Array.from(values(self.tags))
    },
  }))

export type TagStoreInstance = Instance<typeof TagStore>
