import * as React from 'react'
import { useEffect, useState } from 'react'
import { useEnv } from '../hooks/useEnv'

interface IProps {
  src: string
  onLoad?: () => any
}

const DEFAULT_IMAGE_DATA_URL =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='

export const SecuredImage = (props: IProps) => {
  const [imageDataUrl, setImageDataUrl] = useState(DEFAULT_IMAGE_DATA_URL)
  const { api } = useEnv()

  useEffect(() => {
    ;(async () => {
      const imageDataUrl = await api!.loadImage(props.src)
      setImageDataUrl(imageDataUrl)
      props.onLoad?.()
    })()
  }, [props.src])

  return <img {...props} src={imageDataUrl} />
}
