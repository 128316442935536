import { Instance, types } from 'mobx-state-tree'

export const Category = types
  .model('Category', {
    categoryId: types.optional(types.identifier, 'new'),
    saveCategoryId: types.optional(types.string, 'new'),
    parentId: types.maybeNull(types.reference(types.late(() => Category))),
    children: types.array(types.reference(types.late(() => Category))),
    name: types.optional(types.string, ''),
    active: types.optional(types.boolean, false),
  })
  .actions(self => ({
    setProp: (prop: string, value: any) => {
      self[prop] = value
    },
  }))
  .views(self => ({
    get parent() {
      return self.parentId
    }
  }))

export type CategoryInstance = Instance<typeof Category>
