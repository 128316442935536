import * as React from 'react'
import { styled } from '../../../shared/lib/styled-components'
import { useRef, useState } from 'react'
import { LoadingIndicator } from './LoadingIndicator'

interface IProps {
  onFilesSelected: (files: FileList) => any
}

export const FileUpload = ({ onFilesSelected }: IProps) => {
  const [uploading, setUploading] = useState(false)
  const [isDragging, setIsDragging] = useState(false)
  const hiddenFileInputRef = useRef<HTMLInputElement>()
  const handleUploadClick = () => {
    hiddenFileInputRef.current?.click()
  }

  const handleFileChange = async () => {
    const fileInput = hiddenFileInputRef.current
    if (!fileInput) return
    if (!fileInput.files?.length) return

    setUploading(true)
    await onFilesSelected?.(fileInput.files)
    setUploading(false)
  }

  const handleDragStart = () => {}
  const handleDragOver = (e: DragEvent) => {
    e.preventDefault()
    setIsDragging(true)
  }
  const handleDragLeave = (e: DragEvent) => {
    setIsDragging(false)
  }
  const handleDrop = async (e: DragEvent) => {
    e.preventDefault()
    if (e.dataTransfer?.files) {
      setUploading(true)
      await onFilesSelected?.(e.dataTransfer?.files)
      setUploading(false)
    }
    setIsDragging(false)
  }

  return (
    <Container
      onClick={handleUploadClick}
      droppingFiles={isDragging}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <HiddenFileInput ref={hiddenFileInputRef} type="file" accept="image/*" multiple onChange={handleFileChange} />

      {uploading ? (
        <LoadingIndicator white />
      ) : (
        <>
          <AddButton />
          <AddLabel>Please add your images here</AddLabel>
        </>
      )}
    </Container>
  )
}

const Container = styled.div<{ droppingFiles?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 360px;
  border: 1px solid ${props => props.theme.borderColor};
  background: ${props => props.theme.whiteTransparentColor};
  cursor: pointer;
  transition: all 0.1s ease-out;
  box-shadow: none;
  ${props => props.droppingFiles && `box-shadow: 0 0 0 3px ${props.theme.primaryColor}`};
  &:hover {
    background: ${props => props.theme.whiteTransparentColorHover};
    transition: all 0.1s ease-in;
  }
`

const AddButton = styled.div`
  width: 36px;
  height: 36px;
  background: url('/images/add-button.svg') no-repeat center center;
  margin-bottom: 16px;
`

const AddLabel = styled.div`
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
`

const HiddenFileInput = styled.input`
  display: none;
`
