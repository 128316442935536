import { Instance, types } from 'mobx-state-tree'

export const Votes = types
  .model('Votes', {
    myVote: types.number,
    voteCount: types.number,
    totalScore: types.number,
  })
  .actions(self => ({
    vote() {
      self.myVote = 1
      self.voteCount++
      self.totalScore++
    },
  }))

export type VotesInstance = Instance<typeof Votes>
