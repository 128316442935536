import * as React from 'react'
import { PostingsListItem } from './PostingsListItem'
import { PostingInstance } from '../../../shared/models'

interface IProps {
  postings: PostingInstance[]
  small?: boolean
}

export const PostingsList = ({ postings, small }: IProps) => {
  return (
    <>
      {postings.map(posting => (
        <PostingsListItem {...{ posting, small: small }} key={posting.postingId} />
      ))}
    </>
  )
}
