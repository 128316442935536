import * as React from 'react'
import { useEffect, useMemo } from 'react'
import { styled } from '../../../shared/lib/styled-components'
import { Category } from '../../../shared/models'
import { PostingsList } from '../postings/PostingsList'
import { Sidebar } from '../shared/Sidebar'
import { LoadingIndicator } from '../shared/LoadingIndicator'
import { useParams, useRouteMatch } from 'react-router'
import { IAPIPostingsRequestOptions } from '../../../shared/services/IAPIServiceInterface'
import { observer } from 'mobx-react-lite'
import { useEnv } from '../../../shared/hooks/useEnv'
import { PostingListStore, PostingListStoreInstance } from '../../stores/PostingListStore'
import { useSetViewStore } from '../../hooks/useSetViewStore'
import { useApplicationStore } from '../../hooks/useApplicationStore'

export const ListPostingsPage = observer(() => {
  const { categoryId } = useParams<{ categoryId }>()
  const applicationStore = useApplicationStore()

  const env = useEnv()
  const store: PostingListStoreInstance = useMemo<PostingListStoreInstance>(() => PostingListStore.create({}, env), [])
  useSetViewStore(store)

  const { selectedTags, postingsError, loadingPostings, loadingTags, loadingCategory, postings, tags } = store
  const onNewPage = !!useRouteMatch({ path: '/', exact: true })

  const getPostingsOptions = () => {
    const postingsOptions: IAPIPostingsRequestOptions = { limit: 10, page: 1, active: true }
    if (onNewPage) {
      postingsOptions.sortBy = 'created_at'
      postingsOptions.order = 'DESC'
    } else if (categoryId) {
      postingsOptions.category = categoryId
    }
    if (selectedTags.length > 0) {
      postingsOptions.tags = selectedTags.map(t => t.tagId)
    }
    return postingsOptions
  }

  // Data loading
  useEffect(() => {
    void store.fetchPostings(getPostingsOptions())
  }, [categoryId, selectedTags])

  useEffect(() => {
    void store.fetchTags({ category: categoryId, hasContent: true })
  }, [categoryId])

  // Endless scrolling
  useEffect(() => {
    const scrollHandler = (e: Event) => {
      const target = e.currentTarget as Window
      const scrolledToEnd = target.scrollY >= document.body.scrollHeight - document.body.offsetHeight - 100
      if (scrolledToEnd && !loadingPostings && store.canLoadMorePostings) {
        store.fetchPostings(getPostingsOptions(), true)
      }
    }
    window.addEventListener('scroll', scrollHandler)
    return () => window.removeEventListener('scroll', scrollHandler)
  }, [])

  const handleSelectedTagsChange = tags => {
    store.selectTags(tags)
  }

  const newCategory = Category.create({ categoryId: '1', name: 'new' })
  const loading = loadingPostings || loadingTags
  const category = !onNewPage && applicationStore.categories.getCategory(categoryId)
  return (
    <Container>
      <Sidebar
        category={!loading && (onNewPage ? newCategory : (category.parent ? category.parent : category))}
        subCategory={!loading && (onNewPage ? newCategory : (category.parent && category))}
        tags={tags}
        selectedTags={selectedTags}
        onSelectedTagsChange={handleSelectedTagsChange}
      />
      <PostingsListContainer>
        {postingsError ? (
          <ErrorText>
            <strong>Error:</strong> {postingsError}
          </ErrorText>
        ) : (
          <PostingsList postings={postings} />
        )}
        {loading && <LoadingIndicator white />}
      </PostingsListContainer>
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  padding: 0 6rem;
  margin: 3rem auto;
  display: flex;
  flex-direction: row;
`

const PostingsListContainer = styled.div`
  flex: 1;
`

const ErrorText = styled.div`
  text-align: center;
  strong {
    font-weight: bold;
  }
`
