import { IAPIServiceInterface } from '../../shared/services/IAPIServiceInterface'
import { ApplicationStoreInstance } from './ApplicationStore'
import { types, flow, getEnv, cast, Instance } from 'mobx-state-tree'
import { PaginatableStore } from '../../shared/stores'

interface ISearchStoreEnv {
  api: IAPIServiceInterface
  store: ApplicationStoreInstance
}

export const SearchStore = types
  .compose(
    PaginatableStore,
    types.model('SearchStore', {
      query: types.maybeNull(types.string),
      loading: types.optional(types.boolean, true),
      postingIds: types.array(types.string),
      page: types.optional(types.number, 1),
      canLoadMore: types.optional(types.boolean, true),
    })
  )
  .actions(self => ({
    searchPostings: flow(function* (append: boolean = false) {
      const env = getEnv<ISearchStoreEnv>(self)
      const { store, api } = env
      const { page } = self

      self.loading = true
      const postingsData = yield api.searchPostings(self.query, { page, limit: 10 })
      self.canLoadMore = self.postingIds.length < postingsData._total

      const postingIds: string[] = []
      postingsData._embedded.postings.forEach(postingData => {
        store.postings.putPosting(postingData)
        postingIds.push(postingData.postingId)
      })
      self.postingIds = cast(append ? self.postingIds.concat(postingIds) : postingIds)
      self.loading = false
    }),
    clearPostings: () => (self.postingsIds = []),
    setQuery: (query: string) => (self.query = query),
  }))
  .views(self => {
    const views = {
      get postings() {
        const postingsStore = getEnv<ISearchStoreEnv>(self).store.postings
        return self.postingIds.map(postingId => postingsStore.postings.get(postingId))
      },
    }
    return views
  })

export type SearchStoreInstance = Instance<typeof SearchStore>
