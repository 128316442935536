import * as React from 'react'
import { css, styled } from '../../../shared/lib/styled-components'
import { useApplicationStore } from '../../hooks/useApplicationStore'
import { Link } from 'react-router-dom'
import { Button } from './Elements'
import { useRouteMatch } from 'react-router'

export const Navigation = () => {
  const store = useApplicationStore()
  const onFormPage = !!(useRouteMatch('/postings/add') || useRouteMatch('/postings/:postingId/edit'))
  return (
    <Container>
      <div>
        <NavList>
          {store.user!.apps.map(app => (
            <NavItem key={app.appId}>
              <NavLink href={app.appUri} target="_blank">
                {app.name}
              </NavLink>
            </NavItem>
          ))}
        </NavList>
        <NavList>
          <NavItem active={useRouteMatch({ path: '/', exact: true })}>
            <NavLink as={Link} to={`/`}>
              New
            </NavLink>
          </NavItem>
          {store.categories.parents.map(category => (
            <NavItem key={category.categoryId} active={useRouteMatch({ path: '/categories/' + category.categoryId, exact: true })}>
              <NavLink as={Link} to={`/categories/${category.categoryId}`}>
                {category.name}
              </NavLink>
              {category.children.length > 0 && (
                <Subnavigation>
                  {category.children.map(child => (
                    <SubnavigationItem key={child.categoryId}>
                      <SubnavigationLink as={Link} to={`/categories/${child.categoryId}`}>
                        {child.name}
                      </SubnavigationLink>
                    </SubnavigationItem>
                  ))}
                </Subnavigation>
              )}
            </NavItem>
          ))}
          <NavLink as={Link} to={`/postings/ranking`}>
            Ranking
          </NavLink>
        </NavList>
      </div>
      {!onFormPage && (
        <AddNewButton primary as={Link} to="/postings/add">
          Add new
        </AddNewButton>
      )}
    </Container>
  )
}

const Container = styled.nav`
  position: relative;
  font-family: meister, sans-serif;
  font-weight: bold;
  width: 100%;
  padding: 0 6rem;
  margin: 3rem auto;
`

const NavList = styled.ul`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(233, 227, 199, 0.4);
    margin-bottom: 10px;
  }
`

const NavLink = styled.a`
  text-transform: uppercase;
  color: ${props => props.theme.textColor};
  margin-right: 16px;
  transition: all 0.15s;
  letter-spacing: 1px;
  margin-bottom: 10px;
  display: block;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: ${props => props.theme.primaryColor};
  }
`

const BaseOverlayStyles = css`
  position: absolute;
  border: 1px solid ${props => props.theme.primaryColor};
  padding: 20px 0 10px 20px;
  background: ${props => props.theme.darkGreenColor};
`

const Subnavigation = styled.ul`
  ${BaseOverlayStyles};

  width: 350px;
  z-index: 999;
  top: 40px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s;
  transform: translateY(10px);

  &:before {
    display: block;
    content: '';
    position: absolute;
    top: -10px;
    left: 20px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid ${props => props.theme.primaryColor};
  }
`

const DeepSubnavigation = styled.ol`
  ${BaseOverlayStyles};

  top: -20px;
  left: 0px;
  width: 100%;
  transform: translateX(100%);
  display: none;
`

const SubnavigationItem = styled.li`
  position: relative;
  padding-right: 20px;
  padding-bottom: 7px;
  cursor: pointer;

  &:hover ${DeepSubnavigation} {
    display: block;
  }
`

const SubnavigationLink = styled.a`
  color: ${props => props.theme.primaryColor};
  text-decoration: none;
  background-color: transparent;
  display: block;

  &:hover {
    text-decoration: underline;
    color: ${props => props.theme.darkPrimaryColor};
  }
`

const NavItem = styled.li<{ active?: boolean }>`
  ${NavLink} {
    ${props => props.active && `color: ${props.theme.primaryColor}`};
  }
  
  ${props => props.active && css`
    ${NavLink} {
      color: ${props => props.theme.primaryColor};
    }
  `};

  &:hover {
    ${NavLink} {
      color: ${props => props.theme.primaryColor};
    }

    ${Subnavigation} {
      opacity: 1;
      visibility: visible;
      transform: translateY(0px);
    }
  }
`

const AddNewButton = styled(Button)`
  position: absolute;
  right: 6rem;
  top: 60%;
`
