import * as React from 'react'
import { useEffect, useMemo, useState } from 'react'
import { styled } from '../../../shared/lib/styled-components'
import { FileInstance, PostingInstance } from '../../../shared/models'
import { CommentForm } from '../postings/CommentForm'
import { Gallery } from '../postings/Gallery'
import { Breadcrumb, BreadcrumbArrow, Breadcrumbs, PostingHeadline, RunningText } from '../shared/Elements'
import { Sidebar } from '../shared/Sidebar'
import { Comment } from '../postings/Comment'
import { useApplicationStore } from '../../hooks/useApplicationStore'
import { useParams } from 'react-router'
import { LoadingIndicator } from '../shared/LoadingIndicator'
import { SecuredImage } from '../../../shared/components/SecuredImage'
import { LikeButton } from '../shared/LikeButton'
import { observer, Observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import { PostingDetailStore } from '../../stores/PostingDetailStore'
import { useEnv } from '../../../shared/hooks/useEnv'
import { useSetViewStore } from '../../hooks/useSetViewStore'

export const ShowPostingPage = observer(() => {
  const env = useEnv()
  const applicationStore = useApplicationStore()
  const store = useMemo(() => PostingDetailStore.create({}, env), [])
  useSetViewStore(store)

  const { loadingPosting, loadingComments, posting, comments, currentImage } = store
  const [savedCommentsCount, setSavedCommentsCount] = useState(0)
  const { postingId } = useParams<{ postingId: string }>()

  useEffect(() => {
    void store.fetchPosting(postingId)
  }, [postingId])
  useEffect(() => {
    void store.fetchComments(postingId)
  }, [savedCommentsCount])

  const handleImageChange = (file: FileInstance) => store.selectImage(file)
  const handleCommentSave = () => setSavedCommentsCount(count => count + 1)
  const handleLikeClick = () => applicationStore.postings.votePosting(posting)

  return (
    <Container>
      {loadingPosting ? (
        <LoadingIndicator white />
      ) : (
        posting && (
          <>
            <Sidebar category={posting.category.parent || posting.category} subCategory={posting.category.parent ? posting.category : null} tags={posting.tags} posting={posting} />

            <PostingDetailContainer>
              <Breadcrumbs>
                <Breadcrumb>{posting.category.name}</Breadcrumb>
                <BreadcrumbArrow />
                {posting.category && (
                  <>
                    <Breadcrumb>{posting.category.name}</Breadcrumb>
                    <BreadcrumbArrow />
                  </>
                )}
                <Breadcrumb>{posting.title}</Breadcrumb>
              </Breadcrumbs>

              <PostingHeadline>{posting.title}</PostingHeadline>

              <CurrentImageContainer>
                <CurrentGalleryImage src={currentImage?.getFormatUrl('default')} />
                <ButtonsOnImage>
                  <EditButton to={`/postings/${posting.postingId}/edit`}>
                    <img src="/images/icons/edit.svg" />
                  </EditButton>

                  <LikeButton active={posting.votes.myVote > 0} onClick={handleLikeClick} />
                </ButtonsOnImage>
              </CurrentImageContainer>

              <Section>
                <PostingSectionHeadline>Gallery</PostingSectionHeadline>
                <Gallery files={posting.files} onImageChange={handleImageChange} />
              </Section>

              {posting.description && (
                <Section>
                  <PostingSectionHeadline>Project Description</PostingSectionHeadline>
                  <RunningText>{posting.description}</RunningText>
                </Section>
              )}

              {posting.rationale && (
                <Section>
                  <PostingSectionHeadline>Project Rationale</PostingSectionHeadline>
                  <RunningText>{posting.rationale}</RunningText>
                </Section>
              )}

              {posting.costs && (
                <Section>
                  <PostingSectionHeadline>Project Costs</PostingSectionHeadline>
                  <RunningText>{posting.costs}</RunningText>
                </Section>
              )}

              {posting.results && (
                <Section>
                  <PostingSectionHeadline>Project Results</PostingSectionHeadline>
                  <RunningText>{posting.results}</RunningText>
                </Section>
              )}

              <Section>
                <PostingSectionHeadline>Comments ({posting.comments?.count || 0})</PostingSectionHeadline>
                <CommentForm postingId={postingId} onSave={handleCommentSave} />

                <CommentsList>
                  {loadingComments ? (
                    <LoadingIndicator white />
                  ) : (
                    <>
                      {comments.length === 0 && <NoCommentsNote>No comments yet.</NoCommentsNote>}
                      {comments.map(comment => (
                        <Comment comment={comment} key={comment.commentId} largeAvatar />
                      ))}
                    </>
                  )}
                </CommentsList>
              </Section>
            </PostingDetailContainer>
          </>
        )
      )}
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  padding: 0 6rem;
  margin: 3rem auto;
  display: flex;
  flex-direction: row;
`

const PostingDetailContainer = styled.div`
  flex: 1;
`

const CurrentGalleryImage = styled(SecuredImage)`
  width: 100%;
  margin-bottom: 24px;
  margin-top: 14px;
`

const Section = styled.section`
  margin-bottom: 24px;
`

const PostingSectionHeadline = styled.h4`
  text-transform: uppercase;
  font-size: 13px;
  padding-bottom: 13px;
  border-bottom: 1px solid ${props => props.theme.borderColor};
  font-weight: bold;
  margin-bottom: 24px;
`

const CommentsList = styled.ul`
  border: 1px solid ${props => props.theme.primaryColor};
  background: ${props => props.theme.primaryGreenColor};
  margin-top: 26px;
  padding: 15px;
`

const NoCommentsNote = styled.div`
  text-align: center;
  font-size: 12px;
`

const CurrentImageContainer = styled.div`
  position: relative;
`

const ButtonsOnImage = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 23px;
  right: 8px;
  > * {
    margin: 0 5px;
  }
`

const EditButton = styled(Link)`
  display: block;
  width: 28px;
  height: 28px;
`
