import { flow, getParent, Instance, types } from 'mobx-state-tree'
import { Comment, File, FileInstance, Posting } from '../../shared/models'
import { ApplicationStoreInstance } from './ApplicationStore'

export const PostingDetailStore = types
  .model('PostingDetailStore', {
    loadingPosting: types.optional(types.boolean, true),
    loadingComments: types.optional(types.boolean, true),
    posting: types.maybeNull(types.reference(Posting)),
    comments: types.array(types.reference(Comment)),
    currentImage: types.maybeNull(types.reference(File)),
  })
  .actions(self => ({
    fetchPosting: flow(function* (postingId: string) {
      self.loadingPosting = true
      const store = getParent<ApplicationStoreInstance>(self)
      const posting = yield store.postings.fetchPosting(postingId)
      self.posting = posting
      self.currentImage = posting.files[0]
      self.loadingPosting = false
      return posting
    }),
    fetchComments: flow(function* (postingId: string) {
      self.loadingComments = true
      const store = getParent<ApplicationStoreInstance>(self)
      const comments = yield store.comments.fetchComments(postingId)
      self.comments = comments
      self.loadingComments = false
      return comments
    }),
    selectImage: (file: FileInstance) => {
      self.currentImage = file
    },
  }))

export type PostingDetailStoreInstance = Instance<typeof PostingDetailStore>
