import * as React from 'react'
import { PostingInstance } from '../../../shared/models'
import { styled } from '../../../shared/lib/styled-components'
import { SecuredBackgroundImage } from '../../../shared/components/SecuredBackgroundImage'
import { Button, Headline, RankingHeart } from '../shared/Elements'
import { Link } from 'react-router-dom'

interface IProps {
  posting: PostingInstance
  spot: number
}

export const RankingLeaderboardPosting = ({ posting, spot }: IProps) => {
  const topStyle: React.CSSProperties = {}
  switch (spot) {
    case 1:
      topStyle.height = 390
      break
    case 2:
      topStyle.height = 320
      break
    case 3:
      topStyle.height = 280
      break
  }

  const postingUrl = `/postings/${posting.postingId}`

  return (
    <Container>
      <Top style={topStyle}>
        <BackgroundImageContainer to={postingUrl}>
          <BackgroundImage src={posting.files[0]?.getFormatUrl('default')} />
        </BackgroundImageContainer>
        <RankingHeart>{posting.votes.voteCount}</RankingHeart>
      </Top>
      <Bottom>
        <Headline>{posting.title}</Headline>
        <ReadMoreButton primary large inline as={Link} to={postingUrl}>
          Read More
        </ReadMoreButton>
      </Bottom>
    </Container>
  )
}

const Container = styled.div`
  flex: 1;
  margin: 0 10px;
`

const Top = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  position: relative;
  border: 1px solid ${props => props.theme.primaryColor};
`

const Bottom = styled.div`
  background: ${props => props.theme.whiteTransparentColor};
  padding: 5px;
  margin-top: 3px;
`

const ReadMoreButton = styled(Button)`
  margin-top: 8px;
  margin-bottom: -20px;
`

const BackgroundImageContainer = styled(Link)`
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  flex: 1;
`

const BackgroundImage = styled(SecuredBackgroundImage)`
  flex: 1;
`
