import { Instance, types } from 'mobx-state-tree'
import { rgba } from 'polished'

export const Theme = types.model('Theme', {
  primaryColor: types.optional(types.string, '#DD5A13'),
  darkPrimaryColor: types.optional(types.string, '#963d0c'),
  primaryFontFamily: types.optional(types.string, 'Meister'),
  textColor: types.optional(types.string, '#E1E1BF'),
  whiteTransparentColor: types.optional(types.string, rgba('#E9E3C7', 0.07)),
  whiteTransparentColorHover: types.optional(types.string, rgba('#E9E3C7', 0.15)),
  borderColor: types.optional(types.string, rgba('#E9E3C7', 0.34)),
  primaryGreenColor: types.optional(types.string, '#04260D'),
  darkGreenColor: types.optional(types.string, '#0b1e0d'),
  breakpoints: types.optional(
    types.model('Breakpoints', {
      projectButton: types.number,
      mobile: types.number,
      tablet: types.number,
      desktop: types.number,
    }),
    {
      projectButton: 1400,
      mobile: 0,
      tablet: 737,
      desktop: 1025,
    }
  ),
})

export type ThemeInstance = Instance<typeof Theme>
