import * as React from 'react'
import { useEffect } from 'react'
import { styled } from '../../../shared/lib/styled-components'
import { Button, Headline, RankingHeart, RunningText, SubHeadline } from '../shared/Elements'
import { LikesAndComments } from '../shared/LikesAndComments'
import { LatestComment } from './LatestComment'
import { PostingInstance } from '../../../shared/models'
import { Link } from 'react-router-dom'
import { SecuredBackgroundImage } from '../../../shared/components/SecuredBackgroundImage'
import { useApplicationStore } from '../../hooks/useApplicationStore'
import { Observer } from 'mobx-react-lite'
import { LikeButton } from '../shared/LikeButton'

interface IProps {
  posting: PostingInstance
  small?: boolean
  ranking?: boolean
  className?: string
}

export const PostingsListItem = ({ posting, small, ranking, className }: IProps) => {
  const store = useApplicationStore()
  const { comments: commentStore } = store

  useEffect(() => {
    ;(async () => {
      if (small) return
      await commentStore.fetchComments(posting.postingId)
    })()
  }, [])

  const handleReadMoreClick = () => {
    store.setSearchOpen(false)
  }

  const handleLikeClick = e => {
    e.stopPropagation()
    e.preventDefault()
    store.postings.votePosting(posting)
  }

  const postingUrl = `/postings/${posting.postingId}`

  return (
    <Observer>
      {() => (
        <Container className={className}>
          <Content smallHeight={small}>
            <Metadata>
              {small && ranking && <TopRightRankingHeart small>{posting.votes.voteCount}</TopRightRankingHeart>}
              <Top>
                <Headlines>
                  {!small && <SubHeadline>{posting.author?.country}</SubHeadline>}
                  <Headline>
                    <HeadlineLink to={postingUrl}>{posting.title}</HeadlineLink>
                  </Headline>
                </Headlines>
                {!small && <Date>{posting.formattedDate}</Date>}

                <Description>{posting.getTruncatedDescription(150)}</Description>

                {!small && commentStore.count > 0 && <LatestComment comment={commentStore.latestComment} />}
              </Top>
              <Bottom>
                {!small && <LikesAndComments posting={posting} />}
                <ReadMoreButton primary large inline as={Link} to={postingUrl} onClick={handleReadMoreClick}>
                  Read More
                </ReadMoreButton>
              </Bottom>
            </Metadata>
            <PreviewImageLink to={postingUrl}>
              <PreviewImage src={posting.files[0]?.getFormatUrl('default')} />
              <PositionedLikeButton active={posting.votes.myVote > 0} onClick={handleLikeClick} />
            </PreviewImageLink>
          </Content>
        </Container>
      )}
    </Observer>
  )
}

const Container = styled.div`
  display: flex;
  padding-bottom: 50px;
  justify-content: stretch;
  align-items: stretch;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(233, 227, 199, 0.4);
    margin-bottom: 50px;
  }
`

const Content = styled.div<{ smallHeight?: boolean }>`
  background: ${props => props.theme.whiteTransparentColor};
  min-height: ${props => (props.smallHeight ? '234px' : '430px')};
  display: flex;
  flex-direction: row;
  width: 100%;
`

const Metadata = styled.div`
  position: relative;
  flex: 0 0 265px;
  display: flex;
  flex-direction: column;
  padding: 13px;
  border-top: 1px solid ${props => props.theme.borderColor};
`

const Top = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 2em;
`

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const PreviewImageLink = styled(Link)`
  position: relative;
  flex: 1;
  display: flex;
`

const PreviewImage = styled(SecuredBackgroundImage)`
  flex: 1;
  background: no-repeat center center;
  background-size: cover;
  text-decoration: none;
`

const Date = styled.div`
  font-family: ${props => props.theme.primaryFontFamily};
  font-weight: bold;
  color: ${props => props.theme.primaryColor};
  font-size: 12px;
  margin-bottom: 17px;
`

const Headlines = styled.div`
  margin-bottom: 8px;
`

const Description = styled(RunningText)`
  padding-right: 25px;
  margin-bottom: 2em;
`

const ReadMoreButton = styled(Button)`
  margin-top: 35px;
`

const HeadlineLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`

const TopRightRankingHeart = styled(RankingHeart)`
  position: absolute;
  top: 5px;
  right: 5px;
  left: auto;
  transform-origin: 100% 0;
`

const PositionedLikeButton = styled(LikeButton)`
  position: absolute;
  top: 9px;
  right: 8px;
`
