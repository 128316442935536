import * as React from 'react'
import { Button } from '../shared/Elements'
import { styled } from '../../../shared/lib/styled-components'
import { useHistory } from 'react-router'

export const LoginPage = () => {
  const history = useHistory()
  const onLoginClick = () => {
    history.push('/login/do')
  }

  return (<Container>
    <Button primary enabled onClick={onLoginClick}>Login via GTMPlatform</Button>
  </Container>)
}

const Container = styled.div`
  width: 100%;
  padding: 0 6rem;
  margin: 3rem auto;
  display: flex;
  justify-content: center;
`
