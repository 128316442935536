import * as React from 'react'
import { css, styled } from '../../../shared/lib/styled-components'
import { useApplicationStore } from '../../hooks/useApplicationStore'
import { Observer } from 'mobx-react-lite'

export const SearchBar = () => {
  const store = useApplicationStore()
  const handleFocus = () => {
    store.setSearchOpen(true)
  }

  const handleSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    store.setSearchQuery(e.target.value)
  }

  return (
    <Observer>
      {() => (
        <Container>
          <Content open={store.searchOpen}>
            <form>
              <SearchInput
                open={store.searchOpen}
                placeholder="Search"
                onFocus={handleFocus}
                value={store.searchQuery}
                onChange={handleSearchQueryChange}
              />
            </form>
            <Results />
          </Content>
        </Container>
      )}
    </Observer>
  )
}

const Container = styled.div`
  flex-grow: 2;
  display: flex;
  align-items: center;
  margin-top: 20px;
  z-index: 1002;
  transition: all 0.4s;
  margin-right: 20px;
`

const Content = styled.div<{ open?: boolean }>`
  width: 100%;
  background: #0b1e0d;
  border: 1px solid ${props => props.theme.borderColor};

  ${props =>
    props.open &&
    css`
      border: none;
      border-bottom: 1px solid ${props => props.theme.borderColor};
    `};
`

const SearchInput = styled.input<{ open?: boolean }>`
  display: inline-block;
  background: none;
  border: none;
  color: #dd5a12;
  width: 100%;
  margin: 0;
  font-family: 'Meister-Regular';
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 10px;
  font-size: 15px;
  transition: all 0.3s;

  ${props =>
    props.open &&
    css`
      font-size: 25px;
      padding-left: 60px;
    `};

  &:focus {
    outline: none;
  }
`

const Results = styled.div``
