import { Instance, types } from 'mobx-state-tree'
import { App } from './App'

export const User = types
  .model('User', {
    userId: types.optional(types.identifier, 'new'),
    avatarUri: types.maybeNull(types.string),
    email: types.string,
    nickname: types.string,
    roles: types.array(types.string),
    forename: types.string,
    surname: types.string,
    country: types.string,
    apps: types.array(App),
  })
  .views(self => ({
    get fullName() {
      return `${self.forename} ${self.surname}`
    },
    get isAdmin() {
      return Array.from(self.roles).indexOf('sharing-admin') !== -1
    },
  }))

export type UserInstance = Instance<typeof User>
