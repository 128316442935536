import { Instance, types } from 'mobx-state-tree'

export const GlobalImage = types.model('GlobalImage', {
  url: types.string,
})

export const Globals = types.model('Globals', {
  images: types.model({
    headerBackground: types.maybeNull(GlobalImage),
    bodyBackground: types.maybeNull(GlobalImage),
    similarPostingsBackground: types.maybeNull(GlobalImage),
  }),
})

export type GlobalsInstance = Instance<typeof Globals>
