import * as React from 'react'
import { IEnv } from '../types/IEnv'
import { APIService } from '../services/APIService'

const EnvContext = React.createContext<IEnv>({ api: new APIService() })

export const { Provider } = EnvContext

export function useEnv(mapStateToProps?: Function): IEnv {
  const store = React.useContext(EnvContext)

  if (typeof mapStateToProps !== 'undefined') {
    return mapStateToProps(store)
  }

  return store
}
