import * as React from 'react'
import { FormEvent, useState } from 'react'
import { styled } from '../../../shared/lib/styled-components'
import { Button } from '../shared/Elements'
import { LoadingIndicator } from '../shared/LoadingIndicator'
import { useEnv } from '../../../shared/hooks/useEnv'

interface IProps {
  postingId: string
  onSave?: () => any
}

export const CommentForm = ({ postingId, onSave }: IProps) => {
  const { api } = useEnv()
  const [saving, setSaving] = useState(false)
  const [text, setText] = useState('')

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    ;(async () => {
      setSaving(true)

      await api.sendComment(postingId, text)

      onSave?.()

      setText('')
      setSaving(false)
    })()
  }

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value)
  }

  return (
    <Form onSubmit={handleSubmit}>
      <TextareaContainer>
        <Textarea
          placeholder="Write your commment..."
          rows={5}
          disabled={saving}
          required
          onChange={handleTextChange}
          value={text}
        />
        {saving && (
          <LoadingContainer>
            <LoadingIndicator white />
          </LoadingContainer>
        )}
      </TextareaContainer>
      <Button primary>Post comment</Button>
    </Form>
  )
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Textarea = styled.textarea`
  font-family: ${props => props.theme.primaryFontFamily};
  border: 1px solid ${props => props.theme.primaryColor};
  padding: 13px;
  font-size: 12px;
  color: ${props => props.theme.textColor};
  background: ${props => props.theme.darkGreenColor};
  width: 100%;
  resize: vertical;
  max-height: 300px;
  min-height: 100px;
`

const TextareaContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  margin-bottom: 39px;
  align-self: stretch;
`

const LoadingContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`
