import { flow, getEnv, types } from 'mobx-state-tree'
import { User } from '../models'
import { IAPIServiceInterface } from '../services/IAPIServiceInterface'

interface IEnv {
  api: IAPIServiceInterface
}

export const UserStore = types
  .model('UserStore', {
    users: types.map(User),
  })
  .actions(self => {
    const env = getEnv<IEnv>(self)
    const actions = {
      fetchUsers: flow(function* () {
        const data = yield env.api.fetchUsers()
        actions.putUsers(data.users)
      }),

      fetchUser: flow(function* () {
        let data
        try {
          data = yield env.api.fetchUser()

          if (!data.apps) return null

          data.apps.forEach(app => {
            app.appId = app.app_id
            delete app.app_id
            app.appUri = app.app_uri
            delete app.app_uri
            app.imageUri = app.image_uri
            delete app.image_uri
          })

          if (data.user_id) {
            data.userId = data.user_id
            delete data.user_id
          }

          return actions.putUser(data)
        } catch (e) {
          return null
        }
      }),

      putUsers: data => {
        data.forEach(userData => {
          actions.putUser(userData)
        })
      },

      putUser: data => {
        let userInstance
        if (data.user_id) {
          data.userId = data.user_id
          delete data.user_id
        }
        if (data.acl_roles) {
          data.roles = data.acl_roles.map(r => r.name)
        }

        if (!self.users.has(data.userId)) {
          userInstance = User.create(data)
          self.users.put(userInstance)
        } else {
          userInstance = self.users.get(data.userId)
        }
        return userInstance
      },
    }

    return actions
  })
