import { flow, getEnv, Instance, types } from 'mobx-state-tree'
import { File, FileInstance } from '../models'
import { IAPIServiceInterface } from '../services/IAPIServiceInterface'

interface IEnv {
  api: IAPIServiceInterface
}

export const FileStore = types
  .model('FileStore', {
    files: types.map(File),
  })
  .actions(self => {
    const env = getEnv<IEnv>(self)
    const actions = {
      // -------------------------------------------------------------------------- MANAGEMENT

      putFiles: (data): FileInstance[] => {
        const files: FileInstance[] = []
        data.forEach(fileData => {
          if (!self.files.has(fileData.fileId)) {
            const file = File.create(fileData)
            files.push(file)
            self.files.put(file)
          }
        })
        return files
      },

      deleteFile: flow(function* (file: FileInstance) {
        yield env.api.deleteFile(file.fileId)
      }),
    }
    return actions
  })

export type FileStoreInstance = Instance<typeof FileStore>
