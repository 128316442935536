import * as React from 'react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import * as _ from 'lodash'
import { css, styled } from '../../../shared/lib/styled-components'
import { useApplicationStore } from '../../hooks/useApplicationStore'
import { observer } from 'mobx-react-lite'
import { LoadingIndicator } from './LoadingIndicator'
import { SearchStore } from '../../stores/SearchStore'
import { PostingsListItem } from '../postings/PostingsListItem'
import { useEnv } from '../../../shared/hooks/useEnv'

interface IProps {
  isVisible?: boolean
}

export const SearchOverlay = observer((props: IProps) => {
  const { api } = useEnv()
  const store = useApplicationStore()
  const searchStore = useMemo(() => SearchStore.create({}, { api, store }), [])
  const resultListRef = useRef<HTMLDivElement>()

  const [loading, setLoading] = useState(false)
  const handleSearchChange = useCallback(
    _.debounce(async query => {
      searchStore.setQuery(query)
      if (!query) {
        return searchStore.clearPostings()
      }
      setLoading(true)
      await searchStore.searchPostings(false)
      setLoading(false)
    }, 500),
    []
  )

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const reachedEnd = e.currentTarget.scrollTop >= e.currentTarget.scrollHeight - e.currentTarget.offsetHeight - 100
    if (reachedEnd && searchStore.canLoadMore && !searchStore.loading) {
      searchStore.searchPostings(true)
    }
  }

  useEffect(() => {
    handleSearchChange(store.searchQuery)
  }, [store.searchQuery])

  return (
    <Container isVisible={props.isVisible}>
      {loading ? (
        <LoadingIndicator white />
      ) : (
        <>
          {searchStore.postingIds.length > 0 && (
            <ResultList onScroll={handleScroll} ref={resultListRef}>
              {searchStore.postings.map(p => (
                <PostingItemContainer>
                  <PostingItem posting={p} small />
                </PostingItemContainer>
              ))}
            </ResultList>
          )}
          {searchStore.loading && <LoadingIndicator white />}
        </>
      )}
    </Container>
  )
})

const Container = styled.div<{ isVisible?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: fixed;
  top: 0;
  background: radial-gradient(circle, #0f2912 0%, #071308 100%);
  left: 0;
  height: 100%;
  z-index: 1001;
  transition: all 0.2s;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.98);
  padding-top: 150px;

  ${props =>
    props.isVisible &&
    css`
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    `};
`

const ResultList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -17px 3px -17px 133px;
  flex: 1;
  overflow-y: auto;
  padding-bottom: 34px;
`

const PostingItem = styled(PostingsListItem)`
  padding-bottom: 0;
  &:not(:last-child) {
    border-bottom: 0;
    margin-bottom: 17px;
  }
`

const PostingItemContainer = styled.div`
  flex: 0 0 50%;
  padding: 17px;
`
