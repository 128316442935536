import * as React from 'react'
import { styled } from '../../../shared/lib/styled-components'

interface IProps {
  onClick?: () => any
  active?: boolean
  className?: string
}

export const LikeButton = ({ onClick, active, className }: IProps) => {
  return <Icon src={active ? '/images/icons/like-active.svg' : '/images/icons/like.svg'} {...{ onClick, className }} />
}

const Icon = styled.img`
  cursor: pointer;
`
