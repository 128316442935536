import * as _ from 'lodash'
import {
  FormColumn,
  FormGroup,
  FormInput,
  FormLabel,
  FormLabelNotice,
  FormRow,
  FormSelect,
  FormTextArea,
} from '../shared/Forms'
import { FileUpload } from '../shared/FileUpload'
import { TagsList } from '../shared/TagsList'
import { FilePreview } from './FilePreview'
import { Button, TagsHeadline } from '../shared/Elements'
import * as React from 'react'
import { UseFormMethods } from 'react-hook-form'
import { styled } from '../../../shared/lib/styled-components'
import { FileInstance, TagInstance } from '../../../shared/models'
import { useApplicationStore } from '../../hooks/useApplicationStore'
import { useEnv } from '../../../shared/hooks/useEnv'
import { observer } from 'mobx-react-lite'

interface IProps {
  form: UseFormMethods
  onSubmit: (data: any) => any
  onUploadedFilesChange: (files: FileInstance[]) => any
  onSelectedTagsChange: (tags: TagInstance[]) => any
  uploadedFiles: FileInstance[]
  selectedTags: FileInstance[]
}

export const PostingForm = observer(
  ({ form, onSubmit, onUploadedFilesChange, onSelectedTagsChange, uploadedFiles, selectedTags }: IProps) => {
    const { register, handleSubmit } = form
    const store = useApplicationStore()
    const { api } = useEnv()

    const handleFilesSelected = async (files: FileList) => {
      const filesData = await api.sendFiles(files)
      const fileInstances = store.files.putFiles(filesData)
      onUploadedFilesChange?.(fileInstances)
    }

    const handleFileDelete = (file: FileInstance) => {
      onUploadedFilesChange?.(_.without(uploadedFiles, file))
    }

    const handleTagsChange = (tags: TagInstance[]) => {
      onSelectedTagsChange?.(tags)
    }

    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <SaveCaseButton primary large>
          Save Case
        </SaveCaseButton>
        <Left>
          <FormGroup>
            <FormRow>
              <FormColumn>
                <FormLabel>Project Title</FormLabel>
                <FormInput type="text" name="title" ref={register} required />
              </FormColumn>
            </FormRow>
          </FormGroup>

          <FormGroup>
            <FormRow>
              <FormColumn>
                <FormLabel>Category</FormLabel>
                <FormSelect name="category" ref={register} required>
                  <option value="">Select...</option>
                  {store.categories.categoryList.map(category => (
                    <option value={category.categoryId} key={category.categoryId}>
                      {category.name}
                    </option>
                  ))}
                </FormSelect>
              </FormColumn>
            </FormRow>
          </FormGroup>

          <FormGroup>
            <FormRow>
              <FormColumn>
                <FormLabel>
                  Project Description
                  <FormLabelNotice>Max 300 Characters</FormLabelNotice>
                </FormLabel>
                <FormTextArea name="description" ref={register} required />
              </FormColumn>
            </FormRow>

            <FormRow>
              <FormColumn>
                <FormLabel>
                  Project Rationale
                  <FormLabelNotice>Optional</FormLabelNotice>
                </FormLabel>
                <FormTextArea name="rationale" ref={register} />
              </FormColumn>
              <FormColumn>
                <FormLabel>
                  Project Results
                  <FormLabelNotice>Optional</FormLabelNotice>
                </FormLabel>
                <FormTextArea name="results" ref={register} />
              </FormColumn>
            </FormRow>

            <FormRow>
              <FormColumn>
                <FormLabel>
                  Project Cost
                  <FormLabelNotice>Optional</FormLabelNotice>
                </FormLabel>
                <FormTextArea name="costs" ref={register} />
              </FormColumn>
              <FormColumn />
            </FormRow>
          </FormGroup>
        </Left>
        <Right>
          <FileUploadContainer>
            <FileUpload onFilesSelected={handleFilesSelected} />
            <UploadedFiles>
              {uploadedFiles.map(file => (
                <FilePreview key={file.fileId} file={file} onDelete={handleFileDelete.bind(null, file)} />
              ))}
            </UploadedFiles>
          </FileUploadContainer>

          <TagsHeadline>Tags</TagsHeadline>
          <TagsList tags={store.tags.instances} selectedTags={selectedTags} onSelectionChange={handleTagsChange} />
        </Right>
      </Form>
    )
  }
)

const Form = styled.form`
  display: flex;
  flex-direction: row;
`

const Left = styled.div`
  flex: 1;
`

const Right = styled.div`
  flex: 0 0 490px;
  display: flex;
  flex-direction: column;
  padding-left: 21px;
`

const UploadedFiles = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 -10px;
  flex-wrap: wrap;
`

const FileUploadContainer = styled.div`
  border-bottom: 1px solid ${props => props.theme.borderColor};
  padding-bottom: 17px;
`

const SaveCaseButton = styled(Button)`
  position: absolute;
  top: -80px;
  right: 6rem;
`
