import { Instance, types } from 'mobx-state-tree'

export const Tag = types
  .model('Tag', {
    tagId: types.optional(types.identifier, 'new'),
    originalTagId: types.maybeNull(types.string),
    name: types.optional(types.string, ''),
    active: types.optional(types.boolean, false),
  })
  .actions(self => ({
    setProp: (prop: string, value: any) => {
      self[prop] = value
    },
  }))

export type TagInstance = Instance<typeof Tag>
