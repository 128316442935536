import * as React from 'react'
import { styled } from '../../../shared/lib/styled-components'
import { CategoryInstance, PostingInstance, TagInstance } from '../../../shared/models'
import { TagsList } from './TagsList'
import { TagsHeadline } from './Elements'

interface IProps {
  category?: CategoryInstance
  subCategory?: CategoryInstance
  posting?: PostingInstance
  tags?: TagInstance[]
  selectedTags?: TagInstance[]
  onSelectedTagsChange?: (tags: TagInstance[]) => any
}

export const Sidebar = ({ category, subCategory, posting, tags, selectedTags, onSelectedTagsChange }: IProps) => {
  return (
    <Container>
      <Tabs>
        <CategoryTab>{category?.name}</CategoryTab>
        <RemainingTab />
      </Tabs>
      {subCategory && <SubCategory>{subCategory.name}</SubCategory>}
      {posting && <PostingItem>{posting.title}</PostingItem>}

      {tags && tags.length > 0 && (
        <TagsContainer>
          <TagsHeadline>Tags</TagsHeadline>
          <TagsList tags={tags} selectedTags={selectedTags} onSelectionChange={onSelectedTagsChange} />
        </TagsContainer>
      )}
    </Container>
  )
}

const Container = styled.div`
  flex: 0 0 440px;
  margin-right: 40px;
  font-weight: bold;
  text-transform: uppercase;
`

const Tabs = styled.div`
  display: flex;
  flex-direction: row;
`

const RemainingTab = styled.div`
  flex: 1;
  border-bottom: 1px solid ${props => props.theme.borderColor};
`

const CategoryTab = styled.div`
  background: ${props => props.theme.whiteTransparentColor};
  border: 1px solid ${props => props.theme.borderColor};
  border-radius: 3px 3px 0 0;
  border-bottom: 0;
  padding: 0 16px;
  line-height: 41px;
  color: ${props => props.theme.primaryColor};
  font-size: 13px;
`

const ListItem = styled.div`
  position: relative;
  line-height: 61px;
  padding: 0 21px;
  border-bottom: 1px solid ${props => props.theme.borderColor};
  font-size: 12px;
`

const SubCategory = styled(ListItem)`
  border-right: 1px solid ${props => props.theme.borderColor};
  color: ${props => props.theme.primaryColor};
  background: ${props => props.theme.whiteTransparentColor};
  &:before {
    content: '';
    display: block;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
    height: 15px;
    background: url('/images/icons/sidebar-arrow.svg') no-repeat center center;
  }
`

const PostingItem = styled(ListItem)`
  padding-left: 41px;
`

const TagsContainer = styled.div`
  margin-top: 28px;
`
