import * as React from 'react'
import { ApplicationStoreInstance } from '../stores/ApplicationStore'

const MSTContext = React.createContext(null)

export const { Provider } = MSTContext

export function useApplicationStore(mapStateToProps?: Function): ApplicationStoreInstance {
  const store = React.useContext<ApplicationStoreInstance>(MSTContext)

  if (typeof mapStateToProps !== 'undefined') {
    return mapStateToProps(store)
  }

  return store
}
