import * as React from 'react'
import { FileInstance } from '../../../shared/models'
import { styled } from '../../../shared/lib/styled-components'
import { SecuredBackgroundImage } from '../../../shared/components/SecuredBackgroundImage'
import { rgba } from 'polished'

interface IProps {
  file: FileInstance
  onDelete?: () => any
}

export const FilePreview = ({ file, onDelete }: IProps) => {
  const handleClick = () => {
    if (confirm('Would you really like to delete this file?')) {
      onDelete?.()
    }
  }
  return (
    <Container onClick={handleClick}>
      <Image src={file.getFormatUrl('thumb')} />
      <DeleteOverlay />
    </Container>
  )
}

const DeleteOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => rgba(props.theme.primaryGreenColor, 0.8)} url('/images/icons/delete.svg') no-repeat center
    center;
  opacity: 0;
  transition: all 0.2s ease-in;
`

const Container = styled.div`
  position: relative;
  flex: 0 0 33.3%;
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-items: stretch;
  justify-content: stretch;
  cursor: pointer;

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  &:hover > ${DeleteOverlay} {
    &:hover {
      opacity: 1;
      transition: all 0.2s ease-out;
    }
  }
`

const Image = styled(SecuredBackgroundImage)`
  flex: 1;
  background-size: cover;
  background-position: center center;
  border: 1px solid ${props => props.theme.borderColor};
`
