import * as React from 'react'
import { styled } from '../../../shared/lib/styled-components'
import { CommentInstance } from '../../../shared/models'
import { SecuredBackgroundImage } from '../../../shared/components/SecuredBackgroundImage'

interface IProps {
  comment: CommentInstance
  largeAvatar?: boolean
}

export const Comment = ({ comment, largeAvatar }: IProps) => {
  return (
    <Container>
      <Avatar large={largeAvatar} style={{ backgroundImage: `url(${comment.author.avatarUri})` }} />
      <CommentContent>
        <CommentText>{comment.content}</CommentText>
        <CommentAuthor>{comment.author.fullName}</CommentAuthor>
      </CommentContent>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px 0;
`

const Avatar = styled.div<{ large?: boolean }>`
  width: ${props => (props.large ? '48px' : '30px')};
  height: ${props => (props.large ? '48px' : '30px')};
  background: ${props => props.theme.primaryColor};
  background-size: cover;
  border-radius: 50%;
  margin-right: 12px;
`

const CommentContent = styled.div`
  font-size: 12px;
  line-height: 18px;
`

const CommentText = styled.div`
  font-weight: bold;
`

const CommentAuthor = styled.div``
