import * as React from 'react'
import { useMemo } from 'react'
import { styled } from '../../../shared/lib/styled-components'
import { useForm } from 'react-hook-form'
import { PostingHeadline } from '../shared/Elements'
import { Posting } from '../../../shared/models'
import { LoadingIndicator } from '../shared/LoadingIndicator'
import { useHistory, useParams } from 'react-router'
import { PostingForm } from '../postings/PostingForm'
import { useEnv } from '../../../shared/hooks/useEnv'
import { PostingFormStore } from '../../../shared/stores/PostingFormStore'
import { useSetViewStore } from '../../hooks/useSetViewStore'
import { useAsyncEffect } from '../../hooks/useAsyncEffect'

interface IProps {}

export const EditPostingPage = ({}: IProps) => {
  const { postingId } = useParams<{ postingId: string }>()

  const env = useEnv()
  const store = useMemo(() => PostingFormStore.create({}, env), [])
  useSetViewStore(store)

  const { loadingCategories, loadingTags, loadingPosting, selectedTags, uploadedFiles, saving } = store

  const form = useForm()
  const history = useHistory()

  const loading = loadingCategories || loadingTags || loadingPosting

  useAsyncEffect(async () => {
    const posting = await store.fetchPosting(postingId, true)

    const { title, description, rationale, results, cost } = posting
    form.reset({
      title,
      description,
      rationale,
      results,
      cost,
      category: posting.category.categoryId,
    })
  }, [])

  useAsyncEffect(async () => {
    await Promise.all([store.fetchCategories(), store.fetchTags()])
  }, [])

  const onSubmit = async data => {
    const posting = store.editingPosting

    data.tags = selectedTags.map(t => t.tagId)
    data.files = uploadedFiles.map(f => f.fileId)
    data.author = posting.author

    const newPosting = Posting.create({
      postingId: 'editing',
      originalPostingId: posting.originalPostingId,
      ...data,
    })

    store.editPosting(newPosting)
    await store.savePosting()
    history.push(`/postings/${posting.originalPostingId}`)
  }

  return (
    <Container>
      <PostingHeadline primaryColored withMarginBottom>
        Edit a case
      </PostingHeadline>

      {loading || saving ? (
        <LoadingIndicator white />
      ) : (
        <PostingForm
          {...{ form, onSubmit, selectedTags, uploadedFiles }}
          onSelectedTagsChange={store.setSelectedTags}
          onUploadedFilesChange={store.setUploadedFiles}
        />
      )}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  padding: 0 6rem;
  margin: 3rem auto;
  display: flex;
  flex-direction: column;
`
