import { cast, flow, getEnv, getParent, Instance, types } from 'mobx-state-tree'
import { Category, File, FileInstance, Posting, PostingInstance, Tag, TagInstance } from '../models'
import { IEnv } from '../types/IEnv'
import { ApplicationStoreInstance } from '../../application/stores/ApplicationStore'

export const PostingFormStore = types
  .model('PostingFormStore', {
    loadingPosting: types.optional(types.boolean, true),
    loadingCategories: types.optional(types.boolean, true),
    loadingTags: types.optional(types.boolean, true),
    editingPosting: types.maybeNull(Posting),
    saving: types.optional(types.boolean, false),
    uploadedFiles: types.array(types.reference(File)),
    categories: types.array(types.reference(Category)),
    tags: types.array(types.reference(Tag)),
    selectedTags: types.array(types.reference(Tag)),
    posting: types.maybeNull(Posting),
  })
  .actions(self => ({
    editPosting: (posting: PostingInstance) => {
      self.editingPosting = posting
    },
    fetchPosting: flow(function* (postingId: string) {
      const applicationStore = getParent<ApplicationStoreInstance>(self)
      self.loadingPosting = true
      const posting = yield applicationStore.postings.fetchPosting(postingId, true)
      self.editingPosting = posting
      self.uploadedFiles = posting.files.map(f => f.fileId)
      self.selectedTags = posting.tags.map(t => t.tagId)
      self.loadingPosting = false
      return posting
    }),
    fetchCategories: flow(function* () {
      const applicationStore = getParent<ApplicationStoreInstance>(self)
      self.loadingCategories = true
      const categories = yield applicationStore.categories.fetchCategories()
      self.categories = categories.map(c => c.categoryId)
      self.loadingCategories = false
      return categories
    }),
    fetchTags: flow(function* () {
      const applicationStore = getParent<ApplicationStoreInstance>(self)
      self.loadingTags = true
      const tags = yield applicationStore.tags.fetchTags()
      self.tags = tags.map(t => t.tagId)
      self.loadingTags = false
    }),
    setSelectedTags: (tags: TagInstance[]) => {
      self.selectedTags = cast(tags.map(tag => tag.tagId))
    },
    setUploadedFiles: (files: FileInstance[]) => {
      self.uploadedFiles = cast(files.map(file => file.fileId))
    },
    savePosting: flow(function* () {
      const env = getEnv<IEnv>(self)
      const applicationStore = getParent<ApplicationStoreInstance>(self)
      const response = yield env.api.sendPosting(self.editingPosting!)
      return applicationStore.postings.preparePosting(response)
    }),
  }))
  .views(self => ({
    get loading() {
      return self.loadingPosting || self.loadingCategories || self.loadingTags
    },
  }))

export type PostingFormStoreInstance = Instance<typeof PostingFormStore>
