import { Instance, types } from 'mobx-state-tree'
import { BackendDate } from './types/BackendDate'
import { User } from './User'

export const Comment = types
  .model('Comment', {
    commentId: types.identifier,
    author: types.reference(User),
    content: types.string,
    createdAt: BackendDate,
  })
  .views(self => ({
    get metaInformation() {
      return `${self.author.nickname} (${self.author.country}) - ${self.createdAt!.toLocaleString()}`
    },
  }))

export type CommentInstance = Instance<typeof Comment>
