import { flow, getEnv, Instance, types } from 'mobx-state-tree'
import { Globals, Theme, User } from '../../shared/models'
import { IAPIServiceInterface } from '../../shared/services/IAPIServiceInterface'
import { CategoryStore, CommentStore, FileStore, PostingStore, TagStore, UserStore } from '../../shared/stores'
import { PostingDetailStore, PostingDetailStoreInstance } from './PostingDetailStore'
import { PostingListStore, PostingListStoreInstance } from './PostingListStore'
import { RankingStore, RankingStoreInstance } from './RankingStore'
import { PostingFormStore, PostingFormStoreInstance } from '../../shared/stores/PostingFormStore'

interface IEnv {
  api: IAPIServiceInterface
}

export const ApplicationStore = types
  .model('ApplicationStore', {
    state: types.optional(types.enumeration('State', ['loading', 'done', 'error']), 'done'),
    theme: types.optional(Theme, {}),
    globals: types.optional(Globals, {
      images: {
        headerBackground: {
          url: '/images/body-background.jpg',
        },
        bodyBackground: {
          url: '/images/body-background.jpg',
        },
        similarPostingsBackground: {
          url: '/images/similar-background.jpg',
        },
      },
    }),

    accessToken: types.maybeNull(types.string),

    // Data
    postings: types.optional(PostingStore, {}),
    tags: types.optional(TagStore, {}),
    files: types.optional(FileStore, {}),
    categories: types.optional(CategoryStore, {}),
    users: types.optional(UserStore, {}),
    comments: types.optional(CommentStore, {}),

    loadingTags: types.optional(types.boolean, false),
    loadingCategories: types.optional(types.boolean, false),

    user: types.maybeNull(types.reference(User)),
    searchOpen: types.optional(types.boolean, false),
    searchQuery: types.optional(types.string, ''),

    viewStore: types.maybeNull(types.union(PostingDetailStore, PostingListStore, RankingStore, PostingFormStore)),
  })
  .actions(self => {
    const actions = {
      setViewStore: (
        store: PostingDetailStoreInstance | PostingListStoreInstance | RankingStoreInstance | PostingFormStoreInstance
      ) => {
        self.viewStore = store
      },
      logout: () => {
        const { api } = getEnv<IEnv>(self)

        self.user = null
        actions.setAccessToken(null)
        api.setAccessToken(null)
        window.localStorage.removeItem('access_token')
      },

      fetchOwnUser: flow(function* () {
        self.user = yield self.users!.fetchUser()
      }),

      setAccessToken: (accessToken: string | null) => {
        self.accessToken = accessToken
      },

      setSearchOpen: (open: boolean) => {
        self.searchOpen = open
      },

      setSearchQuery: (searchQuery: string) => {
        self.searchQuery = searchQuery
      },
    }
    return actions
  })
  .views(self => ({
    get loggedIn() {
      return !!self.user
    },
  }))

export type ApplicationStoreInstance = Instance<typeof ApplicationStore>
