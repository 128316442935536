import { css, styled } from '../../../shared/lib/styled-components'

export const FormGroup = styled.div`
  margin-bottom: 32px;
`

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 -10px 16px -10px;
`

export const FormColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
`

export const FormLabel = styled.div`
  font-family: ${props => props.theme.primaryFontFamily};
  text-transform: uppercase;
  font-weight: bold;
  line-height: 29px;
  font-size: 15px;
  letter-spacing: 2px;
`

export const FormLabelNotice = styled.div`
  float: right;
  color: ${props => props.theme.primaryColor};
  font-size: 12px;
  letter-spacing: 1px;
`

const DefaultInputStyle = css`
  font-family: ${props => props.theme.primaryFontFamily};
  background: ${props => props.theme.primaryGreenColor};
  color: ${props => props.theme.textColor};
  border: 1px solid ${props => props.theme.borderColor};
  font-size: 14px;
  padding: 10px;
  border-radius: 2px;
  box-shadow: none;
  transition: all 0.1s ease-out;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${props => props.theme.primaryColor};
    transition: all 0.1s ease-in;
    border-color: ${props => props.theme.primaryGreenColor};
  }
`

export const FormInput = styled.input`
  ${DefaultInputStyle};
`

export const FormTextArea = styled.textarea`
  ${DefaultInputStyle};
  height: 160px;
  resize: none;
`

export const FormSelect = styled.select`
  ${DefaultInputStyle};
  background: ${props => props.theme.whiteTransparentColor};
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 2px;
`
