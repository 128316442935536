import { Instance, types } from 'mobx-state-tree'

const IMAGE_FORMATS = {
  overlay: [300, 300],
}

export const App = types
  .model('App', {
    appId: types.string,
    name: types.string,
    appUri: types.string,
    imageUri: types.string,
  })
  .views(self => ({
    getImageFormat: (format: 'overlay') => {
      const [width, height] = IMAGE_FORMATS[format]
      return self.imageUri.replace('%d', width.toString()).replace('%d', height.toString())
    },
  }))

export type AppInstance = Instance<typeof App>
