import { flow, getEnv, getParent, Instance, types } from 'mobx-state-tree'
import { Comment, CommentInstance, Posting } from '../models'
import { IAPIServiceInterface } from '../services/IAPIServiceInterface'
import { AdministrationStoreInstance } from '../../administration/stores/AdministrationStore'

interface IEnv {
  api: IAPIServiceInterface
}

export const CommentStore = types
  .model('CommentStore', {
    comments: types.map(Comment),
  })
  .actions(self => {
    const env = getEnv<IEnv>(self)
    const parent = getParent<AdministrationStoreInstance>(self)
    const actions = {
      fetchComments: flow(function* (postingId: string) {
        const response = yield env.api!.fetchComments(postingId)
        const comments = actions.putComments(response._embedded.comments)
        return comments
      }),

      putComments: data => {
        const comments: CommentInstance[] = []
        data.forEach(commentData => {
          comments.push(actions.putComment(commentData))
        })
        return comments
      },

      putComment: data => {
        const user = parent.users.putUser(data.author)
        data.author = user.userId

        const commentInstance = Comment.create(data)

        if (!self.comments.has(data.categoryId)) {
          self.comments.put(commentInstance)
        } else {
          self.comments.set(commentInstance.commentId, commentInstance)
        }
        return commentInstance
      },

      deleteComment: flow(function* (postingId: string, comment: CommentInstance) {
        yield env.api.deleteComment(postingId, comment.commentId)
      }),
    }
    return actions
  })

export type CommentStoreInstance = Instance<typeof CommentStore>
