import { css, styled } from '../../../shared/lib/styled-components'

export const SectionHeadline = styled.h2`
  transform: translateY(20px);
  position: relative;
  z-index: 3;
  line-height: 28px;
  font-size: 30px;
  font-weight: bold;
  transition: all 0.52s;
  margin-bottom: 0.5rem;
  font-family: meister;

  &:after {
    transition: all 0.2s;
    position: relative;
    z-index: -1;
    content: '';
    display: block;
    width: 0%;
    height: 10px;
    background: #e9e3c7;
    right: 0;
    transform: translate(-10px, -10px);
  }
`

export const Button = styled.button<{ small?: boolean; inline?: boolean; primary?: boolean }>`
  &:not(:disabled) {
    cursor: pointer;
  }

  text-decoration: none;
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 10px 25px;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  font-family: ${props => props.theme.primaryFontFamily};
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 13px;

  ${props =>
    props.small &&
    css`
      padding: 0.25rem 0.5rem;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.2rem;
    `};

  ${props =>
    props.primary &&
    css`
      color: ${props.theme.textColor};
      background-color: ${props.theme.primaryColor};
      border-color: ${props.theme.primaryColor};

      &:hover {
        color: ${props.theme.textColor};
        background-color: ${props => props.theme.darkGreenColor};
        border-color: ${props.theme.textColor};
      }
    `};
`

const BaseHeadlineCSS = css`
  font-family: ${props => props.theme.primaryFontFamily};
  font-weight: bold;
  text-transform: uppercase;
`

export const SubHeadline = styled.h3`
  ${BaseHeadlineCSS};
  font-size: 14px;
  color: ${props => props.theme.primaryColor};
  line-height: 14px;
`

export const Headline = styled.h2`
  ${BaseHeadlineCSS};
  font-size: 20px;
  color: ${props => props.theme.textColor};
  line-height: 20px;
`

export const PostingHeadline = styled.h1<{ withMarginBottom?: boolean; primaryColored?: boolean }>`
  ${BaseHeadlineCSS};
  font-size: 24px;
  color: ${props => (props.primaryColored ? props.theme.primaryColor : props.theme.textColor)};
  line-height: 24px;
  ${props => props.withMarginBottom && `margin-bottom: 1em`};
`

export const RunningText = styled.div`
  font-family: ${props => props.theme.primaryFontFamily};
  font-size: 12px;
  line-height: 18px;
  font-weight: normal;
`

export const Breadcrumbs = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 13px;
  color: ${props => props.theme.primaryColor};
  margin-bottom: 26px;
`

export const Breadcrumb = styled.div`
  display: inline-block;
`

export const BreadcrumbArrow = styled.div`
  display: inline-block;
  padding: 0 5px;

  &:before {
    content: '>';
    display: block;
  }
`

export const RankingHeart = styled.div<{ small?: boolean }>`
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(-80%);
  background: url('/images/ranking-heart.svg') no-repeat center center;
  background-size: contain;
  width: 80px;
  height: 70px;
  line-height: 65px;
  text-align: center;
  font-size: 35px;
  font-weight: bold;
  color: ${props => props.theme.primaryGreenColor};
  ${props => props.small && `transform: scale(0.5)`};
`

export const TagsHeadline = styled.h3`
  line-height: 21px;
  padding-left: 30px;
  color: ${props => props.theme.primaryColor};
  margin-top: 28px;
  background: url('/images/icons/tags.svg') no-repeat left center;
  font-size: 15px;
  margin-bottom: 28px;
  text-transform: uppercase;
`
