import { Instance, types } from 'mobx-state-tree'
import { IAPIServiceInterface } from '../services/IAPIServiceInterface'

export interface IPaginatableStoreEnv {
  api: IAPIServiceInterface
}

export const PaginatableStore = types
  .model('PaginatableStore', {
    itemsPerPage: types.optional(types.number, 10),
    totalItems: types.optional(types.number, 0),
    currentPage: types.optional(types.number, 1),
  })
  .views(self => {
    const views = {
      hasPreviousPage: () => {
        return self.currentPage > 1
      },
      hasNextPage: () => {
        return self.currentPage < views.pageCount
      },
      get previousPage() {
        return self.currentPage - 1
      },
      get nextPage() {
        return self.currentPage + 1
      },
      get pageCount() {
        return Math.ceil(self.totalItems / self.itemsPerPage)
      },
    }
    return views
  })

export type PaginatableStoreInstance = Instance<typeof PaginatableStore>
