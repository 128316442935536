import * as qs from 'qs'
import Timeout = NodeJS.Timeout

export interface IOAuthOptions {
  localStorageKey?: string
  apiEndpoint: string
  clientId: string
  redirectUri: string
}

export class OAuth {
  private _options: IOAuthOptions
  private _state: string
  private _window?: Window | null
  private _pollInterval?: Timeout | null
  private _accessToken: string | null

  constructor (options: IOAuthOptions) {
    this._options = Object.assign({
      localStorageKey: 'accessToken'
    }, options)

    this._accessToken = window.localStorage.getItem(this._options.localStorageKey!)
  }

  // -------------------------------------------------------------------------- LOGIN PROCESS

  async login () {
    this._openLoginWindow()
    this._accessToken = await this._waitForAccessToken()
    window.localStorage.setItem(this._options.localStorageKey!, this._accessToken)
  }

  private _openLoginWindow () {
    const width = Math.min(window.outerWidth, 800)
    const height = Math.min(window.outerHeight, 500)

    const options = {
      width, height,
      top: window.screenY + ((window.outerHeight - height) / 2.5),
      left: window.screenX + ((window.outerWidth - width) / 2)
    }

    this._state = Math.random().toString(36).substring(2)
    const query = {
      client_id: this._options.clientId,
      redirect_uri: this._options.redirectUri,
      response_type: 'token',
      state: this._state
    }

    this._window = window.open(
      this._options.apiEndpoint + '?' + qs.stringify(query),
      '_blank',
      qs.stringify(options, { delimiter: ',' })
    )

    if (!this._window) {
      throw new Error('Login window could not be opened')
    }
  }

  private async _waitForAccessToken (): Promise<string> {
    return new Promise((resolve, reject) => {
      this._pollInterval = setInterval(() => {
        if (!this._window || this._window!.closed) {
          this._stopPolling()
          return reject(new Error('Login window has been closed'))
        }

        if (OAuth.redirectOccurred(this._window!)) {
          const parsed = OAuth.parseHash(this._window!)
          if (parsed.state !== this._state) {
            this._stopPolling()
            return reject(new Error('State mismatch.'))
          }
          this._stopPolling()
          return resolve(parsed.access_token)
        }
      }, 200)
    })
  }

  private _stopPolling () {
    if (this._pollInterval) {
      clearInterval(this._pollInterval)
      this._pollInterval = null
    }

    if (this._window && !this._window.closed) {
      this._window.close()
      this._window = null
    }
  }

  removeAccessToken () {
    window.localStorage.removeItem(this._options.localStorageKey!)
  }

  // -------------------------------------------------------------------------- GETTERS

  hasAccessToken (): boolean { return !!this._accessToken }
  get accessToken () { return this._accessToken }

  // -------------------------------------------------------------------------- UTILS

  static redirectOccurred (theWindow: Window = window): boolean {
    return this.hasHash(theWindow) && this.parseHash(theWindow).access_token
  }

  static hasHash (theWindow: Window = window) {
    let hash = false
    try {
      hash = theWindow.location.search || theWindow.location.hash
    } catch (e) {}
    return hash
  }

  static parseHash (theWindow: Window = window) {
    const search = theWindow.location.search.substring(1).replace(/\/$/, '')
    const hash = theWindow.location.hash.substring(1).replace(/[\/$]/, '')
    return search ? qs.parse(search) : qs.parse(hash)
  }
}
