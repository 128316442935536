import * as React from 'react'

export const HeartIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15.403}
      height={13.608}
      viewBox="0 0 15.403 13.608"
      className={props.className}
    >
      <path
        data-name="Path 4"
        d="M14.279 1.337a3.88 3.88 0 00-6.194.446 5.905 5.905 0 00-.383.622 5.9 5.9 0 00-.383-.622 3.88 3.88 0 00-6.194-.446A4.669 4.669 0 000 4.438a5.717 5.717 0 001.575 3.751 35.058 35.058 0 003.941 3.682c.6.508 1.212 1.033 1.869 1.607l.02.017a.451.451 0 00.594 0l.02-.017c.656-.574 1.273-1.1 1.869-1.607a35.053 35.053 0 003.941-3.682A5.718 5.718 0 0015.4 4.438a4.669 4.669 0 00-1.121-3.101zM9.3 11.185c-.514.438-1.043.888-1.6 1.373-.558-.485-1.086-.935-1.6-1.373C2.971 8.517.9 6.754.9 4.438a3.768 3.768 0 01.9-2.5A3.012 3.012 0 014.091.907a3.042 3.042 0 012.49 1.4 5.35 5.35 0 01.691 1.374.451.451 0 00.858 0 5.351 5.351 0 01.691-1.374 2.978 2.978 0 014.781-.371 3.768 3.768 0 01.9 2.5c-.002 2.318-2.07 4.081-5.202 6.749z"
        fill="#dd5a12"
      />
    </svg>
  )
}
