import * as React from 'react'
import { PostingInstance } from '../../../shared/models'
import { styled } from '../../../shared/lib/styled-components'
import { RankingLeaderboardPosting } from './RankingLeaderboardPosting'

interface IProps {
  postings: PostingInstance[]
}

export const RankingLeaderboard = (props: IProps) => {
  const postings = [props.postings[2], props.postings[0], props.postings[1]].filter(Boolean)

  const spots = [3, 1, 2]

  return (
    <Container>
      {postings.map((posting, index) => (
        <RankingLeaderboardPosting posting={posting} spot={spots[index]} />
      ))}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  margin: 0 -10px;
  padding-bottom: 80px;
  border-bottom: 1px solid ${props => props.theme.borderColor};
  margin-bottom: 60px;
`
