import * as React from 'react'
import { Comment } from './Comment'
import { styled } from '../../../shared/lib/styled-components'
import { CommentInstance } from '../../../shared/models'

interface IProps {
  comment: CommentInstance
}

export const LatestComment = ({ comment }: IProps) => {
  return (
    <Container>
      <Headline>Latest Comment</Headline>
      <Comment comment={comment} />
    </Container>
  )
}

const Container = styled.div``

const Headline = styled.h4`
  font-family: ${props => props.theme.primaryFontFamily};
  font-weight: bold;
  color: ${props => props.theme.primaryColor};
  text-transform: uppercase;
  font-size: 12px;
  border-bottom: 1px solid ${props => props.theme.borderColor};
  padding-bottom: 5px;
`
