import { getEnv, Instance, types } from 'mobx-state-tree'
import { IAPIServiceInterface } from '../services/IAPIServiceInterface'

interface IEnv {
  api: IAPIServiceInterface
}

const FORMATS = {
  default: [1000, Math.floor((1000 / 16) * 9)],
  thumb: [215, 10000],
}

export const File = types
  .model('File', {
    fileId: types.identifier,
    name: types.string,
    type: types.string,
    url: types.string,
    size: types.number,
  })
  .views(self => {
    const views = {
      getFormatUrl(format: 'thumb' | 'default' = 'default') {
        let [width, height] = FORMATS[format]
        return self.url.replace('%d', width.toString(10)).replace('%d', height.toString(10))
      },
    }
    return views
  })

export type FileInstance = Instance<typeof File>
