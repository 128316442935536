import { flow, getEnv, getParent, Instance, types } from 'mobx-state-tree'
import { AdministrationStoreInstance } from '../../administration/stores/AdministrationStore'
import { Posting, PostingInstance } from '../models'
import { IAPIPostingsRequestOptions, IAPIServiceInterface } from '../services/IAPIServiceInterface'

interface IEnv {
  api: IAPIServiceInterface
}

export const PostingStore = types
  .model('PostingStore', {
    postings: types.map(Posting),
  })
  .named('PostingStore')
  .actions(self => {
    const env = getEnv<IEnv>(self)
    const parent = getParent<AdministrationStoreInstance>(self)

    const actions = {
      fetchPosting: flow(function* (postingId, forEditing = false) {
        try {
          const data = yield env.api.fetchPosting(postingId) as any
          if (forEditing) {
            data.originalPostingId = data.postingId
            data.postingId = 'editing'
            return actions.preparePosting(data)
          } else {
            return actions.putPosting(data)
          }
        } catch (e) {
          console.error(e)
        }
      }),

      fetchPostings: flow(function* (options: IAPIPostingsRequestOptions, append = false) {
        try {
          const response = yield env.api.fetchPostings(options)

          response._embedded.postings.forEach(data => {
            actions.putPosting(data)
          })

          const postingIds = response._embedded.postings.map(d => d.postingId)
          return postingIds
        } catch (e) {
          console.error(e)
        }
      }),

      searchPostings: flow(function* (title: string, options: IAPIPostingsRequestOptions) {
        try {
          const response = yield env.api.fetchPostings({ title, ...options })

          response._embedded.postings.forEach(data => {
            actions.putPosting(data)
          })
        } catch (e) {
          console.error(e)
        }
      }),

      deletePosting: flow(function* (posting: PostingInstance) {
        try {
          yield env.api.deletePosting(posting.postingId)
        } catch (e) {
          console.error(e)
        }
      }),

      // TODO: Fix this inconsistency. This is caused by the fact that the administration
      //       passes a posting instance while the frontend passes an id.
      softDeletePosting: flow(function* (postingId: string) {
        try {
          yield env.api.softDeletePosting(postingId)
        } catch (e) {
          console.error(e)
        }
      }),

      preparePosting: function (data: any) {
        parent.categories.putCategories([data.category])
        data.category = data.category.categoryId

        parent.tags.putTags(data.tags)
        data.tags = data.tags.map(t => t.tagId)

        parent.files.putFiles(data.files)
        data.files = data.files.map(f => f.fileId)

        const user = parent.users.putUser(data.author)
        data.author = user.userId

        return Posting.create(data)
      },

      putPosting: function (data: any) {
        const postingInstance = actions.preparePosting(data)
        if (!self.postings.has(postingInstance.postingId)) {
          self.postings.put(postingInstance)
        } else {
          self.postings.set(postingInstance.postingId, postingInstance)
        }

        return postingInstance
      },

      votePosting: flow(function* (posting: PostingInstance) {
        if (posting.votes.myVote !== 0) return

        posting.votes.vote()
        yield env.api.votePosting(posting.postingId)

        const matomo = (window as any)._paq
        matomo && matomo.push(['trackEvent', 'Posting', 'Vote', posting.postingId])
      }),
    }
    return actions
  })

export type PostingStoreInstance = Instance<typeof PostingStore>
