import * as Sentry from '@sentry/browser'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Route, Switch } from 'react-router-dom'
import { Reset } from 'styled-reset'
import { useAPIService } from '../../shared/hooks/useAPIService'
import { useApplicationStore } from '../hooks/useApplicationStore'
import { createGlobalStyle, css, styled } from '../../shared/lib/styled-components'
import { DoLoginPage } from './pages/DoLoginPage'
import { ListPostingsPage } from './pages/ListPostingsPage'
import { LoginCallbackPage } from './pages/LoginCallbackPage'
import { ShowPostingPage } from './pages/ShowPostingPage'
import { Footer } from './shared/Footer'
import { Header } from './shared/Header'
import { LoadingIndicator } from './shared/LoadingIndicator'
import { Navigation } from './shared/Navigation'
import { RankingPage } from './pages/RankingPage'
import { NewPostingPage } from './pages/NewPostingPage'
import { Observer } from 'mobx-react-lite'
import { SearchOverlay } from './shared/SearchOverlay'
import { EditPostingPage } from './pages/EditPostingPage'
import { useEnv } from '../../shared/hooks/useEnv'
import { LoginPage } from './pages/LoginPage'

export const MainComponent = () => {
  const [loading, setLoading] = useState(true)
  const { api } = useEnv()
  const store = useApplicationStore()
  const history = useHistory()
  useEffect(() => {
    ;(async () => {
      await api.fetchConfig()

      // Ensure HTTPS
      if (api!.environment !== 'development' && location.protocol !== 'https:') {
        location.href = `https:${window.location.href.substring(window.location.protocol.length)}`
        return
      }

      const accessToken = window.localStorage.getItem('accessToken')
      const onLoginCallbackPage = location.pathname === '/login/callback'
      if (!onLoginCallbackPage) {
        if (accessToken) {
          api!.setAccessToken(accessToken)
          store.setAccessToken(accessToken)
          await store.fetchOwnUser()
          if (!store.loggedIn) {
            history.push('/login/do')
          }
        } else {
          history.push('/login')
        }
      }

      if (api.environment !== 'development') {
        Sentry.init({
          dsn: api.sentryDSN,
          environment: api.environment,
        })
      }

      if (store.loggedIn) {
        // Load global data
        await store.categories.fetchCategories()
      }

      setLoading(false)
    })()
  }, [])

  return (
    <Observer>
      {() => (
        <div>
          <Reset />
          <GlobalStyles searchOpen={store.searchOpen} />

          <SearchOverlay isVisible={store.searchOpen} />
          <Header />
          {!loading && store.loggedIn && <Navigation />}
          <Content>
            {loading ? (
              <LoadingIndicator white />
            ) : (
              <Switch>
                <Route path="/" exact>
                  <ListPostingsPage />
                </Route>
                <Route path="/categories/:categoryId" exact>
                  <ListPostingsPage />
                </Route>
                <Route path="/postings/ranking" exact>
                  <RankingPage />
                </Route>
                <Route path="/postings/add" exact>
                  <NewPostingPage />
                </Route>
                <Route path="/postings/:postingId/edit" exact>
                  <EditPostingPage />
                </Route>
                <Route path="/postings/:postingId" exact>
                  <ShowPostingPage />
                </Route>
                <Route path="/login" exact>
                  <LoginPage />
                </Route>
                <Route path="/login/do" exact>
                  <DoLoginPage />
                </Route>
                <Route path="/login/callback" exact>
                  <LoginCallbackPage />
                </Route>
              </Switch>
            )}
          </Content>
          <Footer />
        </div>
      )}
    </Observer>
  )
}

const GlobalStyles = createGlobalStyle<{ searchOpen?: boolean }>`
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    background-image: url('/images/background.jpg');
    background-size: cover;
    background-attachment: fixed;
    color: #e9e3c7;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    background-color: #e9e3c7;
    position: static;
    width: 100%;
    height: 100%;
    ${props =>
      props.searchOpen &&
      css`
        overflow: hidden;
      `};
  }

  @font-face {
    font-family: 'Meister';
    src: url('/fonts/Meister-Regular.woff');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Meister';
    src: url('/fonts/Meister-Bold.woff');
    font-weight: bold;
  }
`

const Content = styled.div`
  font-family: ${props => props.theme.primaryFontFamily};
  margin-bottom: 10.5rem;
`
