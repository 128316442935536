import { values } from 'mobx'
import { flow, getEnv, Instance, types } from 'mobx-state-tree'
import { Category } from '../models'
import { CategoryInstance } from '../models/Category'
import { IAPIServiceInterface } from '../services/IAPIServiceInterface'

interface IEnv {
  api: IAPIServiceInterface
}

export const CategoryStore = types
  .model('CategoryStore', {
    categories: types.map(Category),
    categoryList: types.array(types.reference(Category)),
    editingCategory: types.maybeNull(Category),

    state: types.optional(types.enumeration(['init', 'loading', 'saving', 'done', 'error']), 'init'),
  })
  .actions(self => {
    const env = getEnv<IEnv>(self)
    const actions = {
      // -------------------------------------------------------------------------- MANAGEMENT

      putCategories: data => {
        const categories: CategoryInstance[] = []
        data.forEach(categoryData => {
          if (categoryData.children) {
            const children = actions.putCategories(categoryData.children)
            categoryData.children = children.map(c => c.categoryId)
          }
          const categoryInstance = Category.create(categoryData)
          categories.push(categoryInstance)
          if (!self.categories.has(categoryData.categoryId)) {
            self.categories.put(categoryInstance)
          } else {
            self.categories.set(categoryInstance.categoryId, categoryInstance)
          }
        })
        return categories
      },

      // -------------------------------------------------------------------------- API INTERACTION

      fetchCategories: flow(function* () {
        self.state = 'loading'
        try {
          const response = yield env.api!.fetchCategories()
          const categories = actions.putCategories(response)
          self.categoryList = categories.map(c => c.categoryId)
          self.state = 'done'
          return categories
        } catch (e) {
          console.error(e)
          self.state = 'error'
        }
      }),

      fetchCategory: flow(function* (categoryId: string) {
        self.state = 'loading'
        try {
          const data = yield env.api.fetchCategory(categoryId) as any
          actions.putCategories([data])
          self.editingCategory = Category.create({ ...data, categoryId: 'editing', saveCategoryId: data.categoryId })
          self.state = 'done'
          return self.editingCategory
        } catch (e) {
          console.error(e)
          self.state = 'error'
        }
      }),

      saveCategory: flow(function* () {
        self.state = 'saving'
        try {
          yield env.api.sendCategory(self.editingCategory!)
          self.state = 'done'
        } catch (e) {
          console.error(e)
          self.state = 'error'
        }
      }),

      getCategory(categoryId: string): CategoryInstance|null {
        return self.categories.get(categoryId)
      },

      deleteCategory: flow(function* (category: CategoryInstance) {
        yield env.api.deleteCategory(category.categoryId)
      }),

      // -------------------------------------------------------------------------- STATE HANDLING

      setState: (state: 'init' | 'loading' | 'saving' | 'done' | 'error') => {
        self.state = state
      },

      // -------------------------------------------------------------------------- HELPERS

      unsetEditingCategory: () => {
        self.state = 'done'
        self.editingCategory = Category.create({})
      },
    }
    return actions
  })
  .views(self => ({
    get options(): [string, string][] {
      return self.categoryList.map(c => [c.categoryId, c.name])
    },
    get sortedCategoryList(): CategoryInstance[] {
      const parents = self.categoryList.filter(c => c.parentId === null)
      const children = self.categoryList.filter(c => c.parentId !== null)
      children.forEach((child, index) => {
        parents.splice(index + 1, 0, child)
      })
      return parents
    },
    get parents(): CategoryInstance[] {
      return self.categoryList.filter(c => c.parentId === null)
    },
  }))

export type CategoryStoreInstance = Instance<typeof CategoryStore>
