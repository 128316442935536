import { History } from 'history'
import * as React from 'react'
import { useMemo } from 'react'
import { useHistory } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import { Provider as EnvProvider } from '../../shared/hooks/useEnv'
import { Provider as ApplicationStoreProvider } from '../hooks/useApplicationStore'
import { ThemeProvider } from '../../shared/lib/styled-components'
import { IAPIServiceInterface } from '../../shared/services/IAPIServiceInterface'
import { ApplicationStore } from '../stores/ApplicationStore'
import { MainComponent } from './MainComponent'
import { IEnv } from '../../shared/types/IEnv'

interface IProps {
  apiService: IAPIServiceInterface
  history?: History
  oauthApiEndpoint: string
  oauthClientId: string
}

export const Application = ({ apiService }: IProps) => {
  const env: IEnv = useMemo<IEnv>(() => ({ api: apiService }), [])
  const store = useMemo(() => {
    return ApplicationStore.create({}, env)
  }, [])
  const history = useHistory()

  history!.listen(location => {
    const matomo = (window as any)._paq
    if (!matomo) return

    matomo.push(['setDocumentTitle', document.domain + '/' + document.title])
    matomo.push(['setCustomUrl', location.pathname])
    matomo.push(['trackPageView'])
  })

  return (
    <ApplicationStoreProvider value={store}>
      <EnvProvider value={env}>
        <ThemeProvider theme={store.theme}>
          <BrowserRouter>
            <MainComponent />
          </BrowserRouter>
        </ThemeProvider>
      </EnvProvider>
    </ApplicationStoreProvider>
  )
}
