import * as React from 'react'
import { styled } from '../../../shared/lib/styled-components'
import { Button } from './Elements'

export const Footer = () => {
  return (
    <Container>
      <Content>
        <Column>
          <Logo src="/images/logo-circle.png" />
          <FooterLink href="https://www.jaegermeister.com" target="_blank">
            A marketing tool by Jägermeister
          </FooterLink>
        </Column>
        <Column right>
          <Button as="a" small primary>
            Need help?
          </Button>
        </Column>
      </Content>
    </Container>
  )
}

const Container = styled.footer`
  border-left: 10px solid ${props => props.theme.primaryColor};
  padding: 20px;
  box-shadow: 0px -20px 30px rgb(7 19 8 / 70%);
  font-family: ${props => props.theme.primaryFontFamily};
  color: ${props => props.theme.textColor};
`

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  align-items: center;
`

const Logo = styled.img`
  width: 50px;
  margin-right: 1rem;
`

const Column = styled.div<{ right?: boolean }>`
  flex: 1;
  padding: 0 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${props => props.right && `justify-content: flex-end`};
`

const FooterLink = styled.a`
  text-transform: uppercase;
  font-size: 80%;
  color: ${props => props.theme.textColor};
  text-decoration: none;
  font-weight: bold;
`
