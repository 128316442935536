import { toJS } from 'mobx'
import * as qs from 'qs'
import * as _ from 'lodash'
import { CategoryInstance } from '../models/Category'
import { PostingInstance } from '../models/Posting'
import { TagInstance } from '../models/Tag'
import {
  APICategoriesResponse,
  APICategoryResponse,
  APICommentResponse,
  APICommentsResponse,
  APIFilesResponse,
  APIPostingResponse,
  APIPostingsResponse,
  APITagResponse,
  APITagsResponse,
  APIUserResponse,
  APIUsersResponse,
  IAPIFileData,
  IAPIPostingsRequestOptions,
  IAPIServiceInterface, IAPITagsRequestOptions,
} from './IAPIServiceInterface'

export class APIService implements IAPIServiceInterface {
  private _authEndpoint: string
  private _authUserApiEndpoint: string
  private _sharingApplicationBaseUrl: string
  private _clientId: string
  private _accessToken: string
  private _sentryDSN: string
  private _environment: 'development' | 'testing' | 'staging' | 'production'

  async fetchConfig(): Promise<void> {
    let request = await fetch('/config.json')
    const data = await request.json()

    this._authEndpoint = data.authorizationEndpointUrl
    this._authUserApiEndpoint = data.userDetailsApiEndpointUrl
    this._sharingApplicationBaseUrl = data.sharingApplicationBaseUrl
    this._clientId = data.clientId
    this._sentryDSN = data.sentryDSN
    this._environment = data.applicationEnvironment
  }

  setAccessToken(accessToken: string) {
    this._accessToken = accessToken
  }

  private async _fetchSharing(path: string, options: any = {}): Promise<any> {
    const url = `${this._sharingApplicationBaseUrl}${path}`
    return this._fetch(url, options)
  }

  private async _fetchUDB(path: string, options: any = {}): Promise<any> {
    const url = `${this._authUserApiEndpoint}${path}`
    return this._fetch(url, options)
  }

  private async _fetch(url: string, options: any = {}) {
    options = Object.assign({}, options, {
      cache: 'no-cache',
      headers: {
        Authorization: `Bearer ${this._accessToken}`,
        Accept: 'application/json',
      },
      credentials: 'include',
    })
    const response = await fetch(url, options)
    const text = await response.text()
    if (response.status > 299) {
      throw new Error('Server did not respond with successful status code.')
    }
    if (text) {
      const jsonResponse = JSON.parse(text)
      if (jsonResponse.errors) {
        throw new Error(this.stringifyErrors(jsonResponse.errors))
      }
      return jsonResponse
    }
    return null
  }

  private async _sendSharing(path: string, body: any, method: string = 'POST') {
    return this._fetchSharing(path, {
      body,
      method,
    })
  }

  async fetchUser(): APIUserResponse {
    return this._fetchUDB('')
  }

  async fetchUsers(): APIUsersResponse {
    return this._fetchUDB('s')
  }

  // -------------------------------------------------------------------------- TAGS

  async fetchTag(tagId: string): APITagResponse {
    return this._fetchSharing('tags/' + tagId)
  }

  async fetchTags(options?: IAPITagsRequestOptions): APITagsResponse {
    return this._fetchSharing('tags?' + qs.stringify(options))
  }

  async sendTag(tag: TagInstance): APITagResponse {
    const path = 'tags' + (tag.tagId !== 'new' ? '/' + (tag.originalTagId || tag.tagId) : '')
    const tagSnapshot: any = toJS(tag)
    return this._sendSharing(path, JSON.stringify(tagSnapshot))
  }

  async deleteTag(tagId: string): Promise<void> {
    return this._fetchSharing('tags/' + tagId, { method: 'DELETE' })
  }

  // -------------------------------------------------------------------------- CATEGORIES

  async fetchCategory(categoryId: string): APICategoryResponse {
    return this._fetchSharing('categories/' + categoryId)
  }

  async fetchCategories(): APICategoriesResponse {
    return this._fetchSharing('categories')
  }

  async sendCategory(category: CategoryInstance): APICategoryResponse {
    const path = 'categories' + (category.categoryId !== 'new' ? '/' + category.categoryId : '')
    const categorySnapshot: any = {
      categoryId: category.categoryId,
      name: category.name,
      parentId: category.parentId?.categoryId || null,
      active: category.active,
    }
    return this._sendSharing(path, JSON.stringify(categorySnapshot))
  }

  async deleteCategory(categoryId: string): Promise<void> {
    return this._fetchSharing('categories/' + categoryId, { method: 'DELETE' })
  }

  // -------------------------------------------------------------------------- POSTINGS

  async searchPostings(value: string, { limit, page }: IAPIPostingsRequestOptions = {}): APIPostingsResponse {
    const data = await this._fetchSharing(
      'postings?' +
        qs.stringify({
          title: value,
          limit,
          page,
          active: 'true',
        })
    )
    return data
  }

  async fetchPosting(postingId: string): APIPostingResponse {
    const data = await this._fetchSharing('postings/' + postingId)
    return data
  }

  async fetchPostings({
    limit,
    page,
    category,
    active,
    categoryActive,
    title,
    sortBy,
    order,
  }: IAPIPostingsRequestOptions = {}): APIPostingsResponse {
    limit = typeof limit === 'undefined' ? 10 : limit
    page = typeof page === 'undefined' ? 1 : page
    const query: any = {
      limit,
      page,
      title,
      category,
      sortBy,
      order,
    }
    if (typeof active !== 'undefined') {
      query.active = active
    }
    if (typeof categoryActive !== 'undefined') {
      query.category_active = categoryActive
    }
    const data = await this._fetchSharing('postings?' + qs.stringify(query))
    return data
  }

  async fetchTopPostings({
    limit,
    page,
    category,
    categoryActive,
    tags,
    active,
  }: IAPIPostingsRequestOptions = {}): APIPostingsResponse {
    limit = typeof limit === 'undefined' ? 10 : limit
    page = typeof page === 'undefined' ? 1 : page

    const query: any = {
      limit,
      page,
      category,
      tags,
      toprated: true,
    }
    if (typeof active !== 'undefined') {
      query.active = active
    }
    if (typeof categoryActive !== 'undefined') {
      query.category_active = categoryActive
    }
    const data = await this._fetchSharing('postings?' + qs.stringify(query))
    return data
  }

  async fetchNewPostings({
    limit,
    page,
    category,
    categoryActive,
    tags,
    active,
  }: IAPIPostingsRequestOptions = {}): APIPostingsResponse {
    limit = typeof limit === 'undefined' ? 10 : limit
    page = typeof page === 'undefined' ? 1 : page
    const query: any = {
      limit,
      page,
      category,
      tags,
      sortBy: 'created_at',
    }
    if (typeof active !== 'undefined') {
      query.active = active
    }
    if (typeof categoryActive !== 'undefined') {
      query.category_active = categoryActive
    }
    const data = await this._fetchSharing('postings?' + qs.stringify(query))
    return data
  }

  async fetchSimilarPostings(posting: PostingInstance): APIPostingsResponse {
    const data = await this._fetchSharing(
      'postings?page=1&' +
        qs.stringify({ limit: 4, categories: [posting.category!.categoryId], active: true, category_active: true })
    )
    return data
  }

  async sendPosting(posting: PostingInstance): APIPostingResponse {
    const postingSnapshot: any = toJS(posting)

    delete postingSnapshot.comments
    delete postingSnapshot.votes
    delete postingSnapshot.createdAt

    // Replace references with ids
    postingSnapshot.author = postingSnapshot.author.userId
    postingSnapshot.category = postingSnapshot.category.categoryId
    postingSnapshot.tags = postingSnapshot.tags.map(t => t.tagId)
    postingSnapshot.files = postingSnapshot.files.map(f => f.fileId)

    const path =
      'postings' + (posting.postingId !== 'new' ? '/' + (posting.originalPostingId || posting.postingId) : '')
    const data = await this._sendSharing(path, JSON.stringify({ ...postingSnapshot }))
    return data
  }

  async softDeletePosting(postingId: string): Promise<void> {
    const path = 'postings/' + postingId
    const data = await this._sendSharing(path, JSON.stringify({ active: false }))
    return data
  }

  async deletePosting(postingId: string): Promise<void> {
    const data = await this._fetchSharing('postings/' + postingId, { method: 'DELETE' })
    return data
  }

  async votePosting(postingId: string): Promise<void> {
    const data = await this._sendSharing('postings/' + postingId + '/vote', JSON.stringify({ vote: 2 }), 'POST')
    return data
  }

  // -------------------------------------------------------------------------- COMMENTS

  async fetchComments(postingId: string): APICommentsResponse {
    const data = await this._fetchSharing('postings/' + postingId + '/comments')
    return data
  }
  async sendComment(postingId: string, content: string): APICommentResponse {
    const path = 'postings/' + postingId + '/comments'
    const data = await this._sendSharing(path, JSON.stringify({ content, active: true }))
    return data
  }
  async deleteComment(postingId: string, commentId: string): Promise<void> {
    const data = await this._fetchSharing('postings/' + postingId + '/comments/' + commentId, { method: 'DELETE' })
    return data
  }

  // -------------------------------------------------------------------------- FILES

  async loadImage(url: string): Promise<string> {
    const data = await fetch(url, {
      headers: {
        Authorization: `Bearer ${this._accessToken}`,
      },
      credentials: 'include',
    })
    const urlCreator = window.URL || (window as any).webkitURL
    const blob = await data.blob()
    return urlCreator.createObjectURL(blob)
  }

  async sendFiles(files: FileList): APIFilesResponse {
    const result: IAPIFileData[] = []
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      const formData = new FormData()
      formData.append('file', file)
      const data = await this._sendSharing('files/upload', formData)
      result.push(data)
    }
    return result
  }

  async deleteFile(file: string): Promise<void> {}

  stringifyErrors(errors: any) {
    const stringifiedErrors: string[] = []
    for (let key in errors) {
      stringifiedErrors.push(`${key}: ${Object.values(errors[key]).join(', ')}`)
    }
    return stringifiedErrors.join(', ')
  }

  // -------------------------------------------------------------------------- GETTERS

  get authEndpoint() {
    return this._authEndpoint
  }
  get authApiEndpoint() {
    return this._authUserApiEndpoint
  }
  get sharingApplicationBaseUrl() {
    return this._sharingApplicationBaseUrl
  }
  get clientId() {
    return this._clientId
  }
  get sentryDSN() {
    return this._sentryDSN
  }
  get environment(): 'development' | 'testing' | 'staging' | 'production' {
    return this._environment
  }
}
