import * as React from 'react'
import { keyframes, styled } from '../../../shared/lib/styled-components'

export const LoadingIndicator = ({ white, className }: { white?: boolean; className?: string }) => {
  return <LoadingSpinner {...{ white, className }} />
}

const spinAnimation = keyframes`
  to {
    transform: rotate(360deg);
  }
`

const LoadingSpinner = styled.div<{ white?: boolean }>`
  width: 4rem;
  height: 4rem;
  margin: 20px auto;
  border-radius: 50%;
  border: 0.3rem solid rgba(${props => (props.white ? '255, 255, 255' : props.theme.primaryColorRGB)}, 0.3);
  border-top-color: rgb(${props => (props.white ? '255, 255, 255' : props.theme.primaryColorRGB)});
  animation: 1.5s ${spinAnimation} infinite linear;
`
