import * as React from 'react'
import { css, styled } from '../../../shared/lib/styled-components'
import { SectionHeadline } from './Elements'
import { SearchBar } from './SearchBar'
import { useApplicationStore } from '../../hooks/useApplicationStore'
import { Observer } from 'mobx-react-lite'

export const Header = () => {
  const store = useApplicationStore()
  const handleLogoClick = () => {
    store.setSearchOpen(false)
  }

  return (
    <Observer>
      {() => (
        <Container>
          <Logo small={store.searchOpen} onClick={handleLogoClick}>
            <LogoImage src="/images/logo-circle.png" />
            <LogoLabel isHidden={store.searchOpen}>
              <GTMLabel>GTM</GTMLabel>
              <PlatformLabel>PLATFORM</PlatformLabel>
            </LogoLabel>
          </Logo>
          {store.loggedIn && <SearchBar />}
        </Container>
      )}
    </Observer>
  )
}

const Container = styled.header`
  display: flex;
`

const LogoImage = styled.img`
  transition: all 0.5s;
  transition-delay: 0.1s;
  width: 75px;
  display: block;
  z-index: 1;
  position: relative;
  margin-right: 20px;
`

const LogoLabel = styled(SectionHeadline)<{ isHidden?: boolean }>`
  transform: translateY(20px);
  transition: all 0.52s;
  opacity: 1;
  ${props =>
    props.isHidden &&
    css`
      opacity: 0;
      transform: translate(-10px, 20px);
    `};
`

const GTMLabel = styled.strong`
  transition: all 0.15s;
  display: block;
`

const PlatformLabel = styled.span`
  transition: all 0.15s;
  display: block;
`

const Logo = styled.div<{ small?: boolean }>`
  width: ${props => (props.small ? '150px' : '400px')};
  padding: 0 30px;
  position: relative;
  z-index: 1002;
  display: flex;
  align-items: center;
  color: #e9e3c7;
  height: 135px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    text-decoration: none;

    ${LogoImage} {
      transform: translateX(3px);
    }

    ${LogoLabel} {
      ${GTMLabel} {
        text-shadow: none;
        color: #dd5a12;
      }

      ${PlatformLabel} {
        color: #0b1e0d;
        text-shadow: -1px -1px 0 #e9e3c7, 1px -1px 0 #e9e3c7, -1px 1px 0 #e9e3c7, 1px 1px 0 #e9e3c7;
      }

      &:after {
        width: 100%;
      }
    }

    &:after {
      width: 120px;
      background-color: #e9e3c7;
    }
  }

  &:after {
    transition: all 0.2s;
    content: '';
    display: block;
    width: 70%;
    height: 15px;
    background: #dd5a12;
    position: absolute;
    left: 0;
    bottom: 23px;
  }
`
