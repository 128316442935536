import { cast, flow, getParent, Instance, types } from 'mobx-state-tree'
import { IAPIPostingsRequestOptions, IAPIServiceInterface } from '../../shared/services/IAPIServiceInterface'
import { Posting, Tag, TagInstance } from '../../shared/models'
import { ApplicationStoreInstance } from './ApplicationStore'

export const RankingStore = types
  .model('RankingStore', {
    postingsError: types.maybeNull(types.string),
    loadingPostings: types.optional(types.boolean, true),
    loadingTags: types.optional(types.boolean, true),
    postings: types.array(types.reference(Posting)),
    tags: types.array(types.reference(Tag)),
    selectedTags: types.array(types.reference(Tag)),
    canLoadMorePostings: types.optional(types.boolean, true),
  })
  .actions(self => ({
    fetchPostings: flow(function* (options: IAPIPostingsRequestOptions, append = false) {
      self.loadingPostings = true
      const store = getParent<ApplicationStoreInstance>(self)
      try {
        const postings = yield store.postings.fetchPostings(options)
        self.postings = cast(append ? [...self.postings, postings] : postings)
        self.loadingPostings = false
        return postings
      } catch (e) {
        self.postingsError = e.message
        self.loadingPostings = false
      }
    }),
    fetchTags: flow(function* () {
      self.loadingTags = true
      const store = getParent<ApplicationStoreInstance>(self)
      const tags = yield store.tags.fetchTags()
      self.tags = tags
      self.loadingTags = false
      return tags
    }),
    selectTags: (tags: TagInstance[]) => {
      self.selectedTags = cast(tags.map(t => t.tagId))
    },
  }))

export type RankingStoreInstance = Instance<typeof RankingStore>
