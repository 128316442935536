import * as React from 'react'
import { useEffect, useMemo } from 'react'
import { styled } from '../../../shared/lib/styled-components'
import { Category } from '../../../shared/models'
import { Sidebar } from '../shared/Sidebar'
import { LoadingIndicator } from '../shared/LoadingIndicator'
import { Breadcrumb, BreadcrumbArrow, Breadcrumbs, PostingHeadline } from '../shared/Elements'
import { RankingLeaderboard } from '../ranking/RankingLeaderboard'
import { PostingsList } from '../postings/PostingsList'
import { useEnv } from '../../../shared/hooks/useEnv'
import { useSetViewStore } from '../../hooks/useSetViewStore'
import { RankingStore } from '../../stores/RankingStore'
import { observer } from 'mobx-react-lite'

export const RankingPage = observer(() => {
  const env = useEnv()
  const store = useMemo(() => RankingStore.create({}, env), [])
  useSetViewStore(store)

  const { loadingPostings, loadingTags } = store
  const loading = loadingPostings || loadingTags

  useEffect(() => {
    void store.fetchPostings({ active: true })
  }, [])

  useEffect(() => {
    void store.fetchTags()
  }, [])

  return (
    <Container>
      {loading ? (
        <LoadingIndicator white />
      ) : (
        <>
          <Sidebar
            category={Category.create({ categoryId: '1', name: 'Sharing' })}
            subCategory={Category.create({ categoryId: '1', name: 'Ranking' })}
            tags={store.tags}
          />
          <ContentContainer>
            <Breadcrumbs>
              <Breadcrumb>SHARING</Breadcrumb>
              <BreadcrumbArrow />
              <Breadcrumb>Ranking</Breadcrumb>
            </Breadcrumbs>

            <PostingHeadline withMarginBottom>Ranking</PostingHeadline>

            <RankingLeaderboard postings={store.postings.slice(0, 3)} />

            <PostingsList postings={store.postings} small />
          </ContentContainer>
        </>
      )}
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  padding: 0 6rem;
  margin: 3rem auto;
  display: flex;
  flex-direction: row;
`

const ContentContainer = styled.div`
  flex: 1;
`
