import * as React from 'react'
import { styled } from '../../../shared/lib/styled-components'
import { TagInstance } from '../../../shared/models'
import * as _ from 'lodash'
import { observer } from 'mobx-react-lite'

interface IProps {
  tags: TagInstance[]
  selectedTags?: TagInstance[]
  onSelectionChange?: (tags: TagInstance[]) => any
}

export const TagsList = observer(({ tags, selectedTags, onSelectionChange }: IProps) => {
  const handleTagClick = (tag: TagInstance) => {
    onSelectionChange?.(_.xor(selectedTags, [tag]))
  }

  return (
    <List>
      {tags.map(tag => (
        <TagItem
          onClick={handleTagClick.bind(null, tag)}
          selected={selectedTags && selectedTags?.indexOf(tag) !== -1}
          key={tag.tagId}
        >
          {tag.name}
        </TagItem>
      ))}
    </List>
  )
})

const List = styled.ul``

const TagItem = styled.li<{ selected?: boolean }>`
  display: inline-block;
  border: 1px solid ${props => props.theme.textColor};
  font-size: 15px;
  margin-right: 10px;
  border-radius: 10px;
  line-height: 21px;
  padding: 0 6px;
  cursor: pointer;
  transition: background-color 0.1s ease-out;
  background-color: transparent;
  ${props =>
    props.selected &&
    `
    background-color: ${props.theme.primaryColor};
    transition: background-color 0.1s ease-in;
  `};
`
